@import 'styles/variables'

.stagingPageContent
  position: relative
  max-width: 100rem
  width: 100vw
  height: calc(100vh - 6.8rem)
  display: flex
  flex-flow: column nowrap
  align-items: stretch
  justify-content: flex-start
  padding: 0

  .stagingContent
    flex: 1 1 auto
    position: relative
    padding: 3rem
    overflow-y: auto
    height: calc(100% - 5rem)
    width: 100vw
    box-sizing: border-box

  .successWrap
    height: 100%
    width: 100vw
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    h2
      margin: 0
      margin-bottom: 1.5rem

    p
      line-height: normal
      font-size: 1.4rem
      margin: 0
      margin-bottom: 4rem

  .statusWrap
    padding: 0 3rem

  .actionRow
    flex: initial
    box-sizing: border-box
    height: 10rem
    padding: 0 3rem
    width: 100vw

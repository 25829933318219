.sectionDescription
  font-size: 1.4rem
  line-height: normal
  margin: 0
  margin-bottom: 3rem
  margin-top: -1rem

.upgradeCategories
  position: relative
  display: flex
  flex-flow: row wrap
  justify-content: flex-start
  gap: 1rem

  &::after
    position: relative
    content: ""
    flex: 50 0 auto

  .toggleButton
    flex: 1 0 auto
    text-align: center
    align-items: center

.materialSelection
  margin-top: 2rem

.deselectButton
  margin-top: 2rem

@import 'styles/variables'
@import 'styles/redesign-variables'

@mixin tableCell
  font-size: 1.4rem
  text-align: left
  vertical-align: middle
  padding: 1.5rem 0
  padding-right: 2rem

.innerLoading
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  width: 4rem !important
  height: 4rem !important

  & > svg
    width: 4rem

.loading
  width: 2rem !important
  height: 2rem !important

  & > svg
    width: 2rem

.bookCreativeCard
  min-height: 6rem
  position: relative

  .note
    background: $blue-100
    padding: 1.5rem
    border: 1px solid $blue-400
    border-radius: 8px
    color: $off-black

    h3
      margin: 0
      margin-bottom: 1.5rem

    p
      font-size: 1.4rem
      line-height: normal
      margin: 0

  h2
    margin: 0
    margin-bottom: 2rem
    font-weight: 500
    font-size: 1.8rem !important

  .selectedCreative
    display: flex
    justify-content: space-between
    align-items: center
    gap: 2rem
    font-size: 1.5rem
    font-weight: bolder
    padding: 1.5rem
    border-radius: 8px
    box-shadow: $box-shadow-md

    .action
      flex: 0 0 auto

  .creatives

    table
      width: 100%
      column-gap: 1rem
      table-layout: fixed

      thead

        th
          @include tableCell
          padding-bottom: 1rem
          padding-top: 0
          color: $gray-700
          border-bottom: 1px solid $gray-700
          font-weight: 500
          font-size: 1.2rem

      tbody

        td
          @include tableCell
          border-bottom: 1px solid $gray-border
          color: $off-black

          &:first-child
            font-weight: bold

          &:last-child
            padding: 0

          &.actionCol
            text-align: right

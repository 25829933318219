@import 'styles/variables'

.hostingInfo

  .title
    margin-bottom: 1.5rem

  p
    margin-bottom: 2rem

  .actionWrap
    display: flex
    flex-flow: row wrap
    align-items: center
    gap: 2rem

    .message
      flex: 1 0

    .spinner
      margin: 0 !important
      height: 4rem !important

      svg
        height: 4rem

/* Move Becka out of the way of flow buttons */
iframe#embeddedMessagingFrame {
  transform: translateY(-50px) !important;
  /* just to be sure there is nothing overriding it */
}

button#embeddedMessagingConversationButton {
  transform: translateY(-50px) !important;
  /* just to be sure there is nothing overriding it */
}

.embedded-messaging>#embeddedMessagingModalOverlay.isMaximized {
  z-index: 1000;
}

@media only screen and (min-width: 100px) and (max-width: 47.9375em) {
  iframe#embeddedMessagingFrame[class~="isMaximized"] {
    transform: translateY(0px) !important;
    z-index: 1001;
  }
}

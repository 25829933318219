@import 'styles/variables'

html[bkbn-style="old"]

  .page.notlogged
    .page-content
      .wrap
        .content
          padding: 5rem
          display: flex
          flex-direction: column
          align-items: flex-start
          background-color: $white
          box-shadow: $primary-card-shadow
          border-radius: $card-border-radius

          section
            margin-bottom: 3rem

            &:last-child
              margin-bottom: 0

            & > *:last-child
              margin-bottom: 0

          h1
            font-size: $h2-size
            margin: 0 0 2rem

          h2
            font-size: $h3-size
            margin: 0 0 2rem

          p
            font-size: 1.6rem
            margin: 0 0 1rem

          .input-group
            margin-top: 2rem

          .status-box
            padding-top: 2rem

          .button
            margin-right: 2rem

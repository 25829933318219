@import 'styles/variables'

.modalContent
  position: relative
  width: calc(100vw - 4rem) !important
  min-height: calc(100vh - 4rem)
  padding: 4rem
  box-sizing: border-box

.modalContentSmall
  position: relative
  width: fit-content !important
  min-height: 62rem
  padding: 4rem
  box-sizing: border-box

  &.changelogContent
    min-height: initial

.inside
  display: flex
  flex-direction: column
  height: 100%
  box-sizing: border-box

.creatives
  position: relative
  overflow: hidden
  border-top: 1px solid transparent

  .selectedByClient
    margin-bottom: 2rem

    .borderWrapper
      padding: 1rem 1.5rem

      &.defaultBorder
        border: 0.3rem solid $secondary-orange-light

      &.redBorder
        border: 0.3rem solid $secondary-red-light

  .creativeList
    width: calc(100vw - 4rem)
    max-width: 100%

    .disabled
      opacity: 0.5

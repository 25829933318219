@import 'styles/redesign-variables'

.dropZone
  border: .1rem dashed $gray-400 !important
  border-radius: .8rem !important
  min-height: 10.8rem !important
  margin-bottom: 1.6rem
  padding: 2rem 1.6rem

  .dragIconContainer
    display: flex
    background: $blue-100
    border-radius: 50%
    cursor: pointer
    width: 3.6rem
    height: 3.6rem

    .dragIcon
      margin: auto
      font-size: 2rem
      color: $blue-600

  .dragInstructions
    font-size: 1.4rem
    color: $gray-900
    font-weight: 400
    margin-top: 1.2rem !important

  .fileRecommendation
    font-size: 1.2rem
    color: $gray-700
    font-weight: 400
    line-height: 1.8rem
    margin-top: .2rem !important

  .uploading
    color: $gray-900
    font-size: 1.4rem
    font-weight: 400
    margin-top: 1.2rem !important

.changeRemuneration

  .inputWrap

    input
      width: 10rem

    strong
      font-size: 1.4rem

    label
      display: block
      margin: 0
      font-size: 1.25rem

  h3
    margin: 0

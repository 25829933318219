@import 'styles/redesign-variables'

.orderDetailAssignmentItem
  border-radius: 0.4rem
  background-color: $white
  border: 0.1rem solid $beige-600
  align-items: flex-start
  padding: 1.6rem
  gap: 1.6rem

  .orderDetailHeader
    display: flex
    flex-direction: column
    width: 100%
    padding-bottom: 1.6rem
    border-bottom: 0.1rem solid $beige-600
    gap: 1.6rem

    .assignmentItemTitle
      font-size: 1.6rem
      font-weight: 600
      line-height: 2.4rem
      color: $gray-900

@import 'styles/variables'

html[bkbn-style="old"]

  .emoji
    display: inline-block
    height: 3.5rem
    width: auto
    vertical-align: middle
    margin: .5rem
    box-shadow: $primary-card-shadow
    border-radius: 100%

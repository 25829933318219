@import 'styles/variables'
@import 'styles/redesign-variables'

.children

  .leftContainer
    width: calc(100vw - 44.8rem)

    @media screen and (max-width: 800px)
      margin-left: 4rem

  .editedPreview
    max-width: 83rem

    @media screen and (max-width: 800px)
      max-width: 32rem

  .rightContainer
    position: fixed
    top: 7rem
    right: 0
    height: calc(100vh - 6.8rem - 6.8rem) // deduct header and footer
    width: 44.8rem
    background-color: $white
    overflow: auto

    .header
      padding: 1.6rem 2.4rem
      border-bottom: .1rem solid $gray-300

      .title
        color: $gray-900
        font-size: 1.8rem
        font-weight: 600

    .body
      padding: 2.4rem 2.4rem 8rem 2.4rem

      .button
        font-family: $font-family
        font-weight: 600
        background-color: $blue-100

        &:hover
          background-color: $blue-200 !important
          box-shadow: 0px 0px 0px 2px rgba($blue-600, 0.3)

        &.active
          background-color: $blue-200

        .icon
          margin-right: .8rem
          font-size: 1.6rem
          color: $blue-600

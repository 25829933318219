@import 'styles/variables'
@import 'styles/redesign-variables'

.wrap
  position: relative
  padding-top: 1rem

  &.disabled,
  &.loading
    pointer-events: none

  &.disabled
    opacity: 0.2

  .savedMethodsList
    margin-bottom: 3rem

  .receiptEmail
    margin-bottom: 3rem

    &.disabled
      opacity: 0.2
      pointer-events: none

  .paymentElement
    opacity: 1
    height: auto
    overflow: hidden
    pointer-events: all
    transition-duration: .3s
    transition-property: opacity, height
    transition-timing-function: ease

    input[type="checkbox"]
      border-radius: 0.8rem !important
      border-color: $gray-400 !important
      background-color: purple !important

    &.disabled,
    &.loading
      pointer-events: none

    &.disabled
      opacity: 0.2

    &.loading
      opacity: 0

  .saveMethodCheckbox
    margin-top: 3rem

    .label
      font-size: 1.4rem
      color: $black

  .checkboxWrapper
    margin-top: 2rem
    padding: 0 1rem

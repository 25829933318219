.modalContent
  padding: 2rem

  .title
    margin-top: 0
    margin-bottom: 3rem

  .popupFooter
    margin-top: 3rem

    .infoMessage
      margin-bottom: 1rem

    .actions
      height: 4rem
      overflow: hidden
      display: flex
      align-items: center
      justify-content: space-between

      .actionButton
        display: flex
        align-content: center

@import 'styles/variables'

.deadlineDate
  border-radius: .8rem
  padding: 1rem 1.5rem
  word-wrap: break-word
  overflow: hidden
  box-sizing: border-box

  &.warning
    background-color: $secondary-orange-light

  &.critical
    background-color: $secondary-red-light

  .dateBlock
    display: flex
    flex-wrap: wrap
    align-items: center
    color: $black
    gap: 1rem

    @media screen and (max-width: 600px)
      flex-direction: column
      align-items: flex-start

    &.locationTimezone
      margin-top: .625rem

    .dateHour
      display: flex
      align-items: center
      margin-left: 0.5rem

    .icon
      &.small
        height: 1.6rem
        margin: 0 .5rem
        vertical-align: text-bottom

    .dateGroup
      display: flex
      flex: 0 0 auto
      align-items: center
      gap: 1rem

      span
        min-height: 1.2em
        display: inline-flex
        text-decoration: none
        transition: color .3s ease

        &.label
          color: #6E6E6E
          margin-right: .5rem

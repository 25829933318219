@import 'styles/redesign-variables'

.saveTemplatePopup
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 45.8rem
  position: relative
  padding: 2.4rem

  .title
    font-weight: 600
    font-size: 2rem
    line-height: 1.5
    color: $gray-900

  .content
    color: $gray-700
    font-size: 1.6rem
    font-weight: 400
    text-align: left
    margin: 2.7rem 0
    display: flex
    flex-direction: column
    line-height: 150%

    .content_default
      display: block

    .content_override
      color: $coral-600
      padding-top: 2.2rem

  .buttons
    display: flex
    justify-content: space-between
    width: 100%

    .button
      font-size: 1.4rem
      font-weight: 600

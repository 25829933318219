@import 'styles/redesign-variables'

.galleryInfoCard
  padding: 1.6rem
  border-radius: .8rem
  background-color: $white
  box-shadow: 0 .1rem .4rem 0 rgba(19, 24, 29, 0.06)

  .title
    width: 100%
    color: $gray-900
    line-height: 2.4rem
    padding-bottom: 1.2rem
    border-bottom: solid .1rem $beige-600
    font-size: 1.6rem
    font-weight: 600

@import 'styles/variables.sass'

.activityToggle
  position: relative
  padding: 1.5rem
  border: 1px solid $gray-border
  border-radius: 8px
  transition: border-color 200ms ease
  cursor: pointer
  margin-top: 5rem

  &:hover
    border-color: $gray-text

  h4
    margin: 0
    color: $black !important

  .toggleButton
    position: absolute
    top: .7rem
    right: 1rem

  p
    margin: 0
    margin-top: 2rem

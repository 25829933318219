html[bkbn-style="old"]

  .tooltip-wrapper
    margin: .5rem

  .tooltip-list
    padding: 0

    .tooltip-list-item
      padding-left: 1rem
      list-style-type: none

      .icon
        padding-right: 1rem

      .line-through
        text-decoration: line-through

      .underline
        text-decoration: underline

      .italic
        font-style: italic

@import 'styles/variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .gallery

    .page-content
      margin-top: 1rem
      max-width: none
      min-height: 30vh

      .page-error
        width: fit-content
        max-width: 100%
        margin: 5rem auto

        a
          transition: color .3s ease
          display: inline-block
          text-decoration: underline
          color: $gray-text

          &:hover
            color: $secondary-orange-dark

        .margin-top
          margin-top: 2rem

        .back-button
          margin-top: 4rem

      .order-detail-wrapper
        box-sizing: border-box

      .assignment-tabs
        margin-top: 3rem

      .gallery-content
        margin-top: 1rem
        display: flex
        flex-direction: row-reverse

        @media screen and (max-width: 1000px)
          flex-direction: column

        .error-wrap
          display: flex
          flex-direction: column
          justify-content: center
          width: 100%
          min-height: 100%
          text-align: center

          .error
            padding-bottom: 1.5rem

          .error-contact-wrap
            display: flex
            justify-content: center

            .error-contact
              width: fit-content

        .main-content-wrapper
          width: calc(100% - 45rem - 1rem)
          display: flex
          flex-direction: column
          margin-right: 1rem
          flex: auto

          @media screen and (max-width: 1000px)
            width: 100%

          .main-content
            position: static !important

            &.virtual-visit
              min-height: 100%
              display: flex
              flex-direction: column

          .photo-grid
            width: 100%
            display: grid
            gap: 1rem
            grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr))

            .image-wrap
              box-sizing: border-box

            .galleryimage
              margin: 0
              width: 100%
              height: 0
              padding-top: calc(20rem / 30rem * 100%)

          .drop-zone
            height: 45rem
            margin: 0 1rem 0 0

            @media screen and (max-width: 1000px)
              height: 20rem

            &.margin-bottom
              margin-bottom: 1rem

            .upload-instructions
              font-size: 1.6rem
              color: $black
              margin-bottom: 1.5rem

          .instructions-wrap
            position: relative
            width: 100%
            margin-bottom: 1rem

            &:only-child
              width: auto
              margin: auto

              .blockinfo
                h2
                  margin-bottom: 1rem

                  p
                    font-weight: bold

          .virtual-visit-input-wrap
            display: flex
            flex-direction: column
            margin-bottom: 1rem

            .line
              display: flex

            .virtual-visit-input
              flex-grow: 1

            .submit
              margin-left: 1rem

          .virtual-visit-embed
            position: relative
            width: 100%
            padding-bottom: 56.25% // for 16:9 aspect ratio - most common

            iframe
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%

      label.checkbox
        margin-right: 2rem

      label
        margin: 1rem 0 2rem

      .input-group
        display: flex
        justify-content: space-between

        input
          width: 100%
          margin-right: 2rem

      .embedded-header
        display: flex
        align-items: center

      .details-wrapper
        max-width: 45rem
        box-sizing: border-box

        &:empty
          padding-top: 0

        & > *:not(:last-child)
          margin-bottom: 1rem

        .details
          padding: 2rem
          border: .1rem solid $gray-border

          &.noPadding
            padding: 0

          .gallery-link-wrapper
            display: flex
            align-items: center

            .gallery-link-info
              flex: 0 0 60%

              span
                display: block

            .gallery-link-button
              margin-left: 2rem

          .sticky
            position: sticky !important
            top: 2rem
            z-index: 1

            .part
              margin-bottom: 2rem

              .button-wrapper
                display: flex
                align-items: center

                @media screen and (max-width: 600px)
                  flex-direction: column
                  align-items: flex-start

                .button
                  flex: 0 0 auto

                  @media screen and (max-width: 600px)
                    margin-bottom: .3rem

              .matterport-transfer-info
                display: flex
                flex-flow: row nowrap
                gap: .5rem

                .icon
                  font-size: 2rem

            .flex
              display: flex
              align-items: center

            .animation-wrap
              padding-bottom: 2rem

              &.nopadding
                padding-bottom: 0

            *
              &.space-right
                margin-right: 2rem

              &.space-top
                margin-top: 2rem

              &.space-bottom
                margin-bottom: 2rem

              &.space-bottom-large
                margin-bottom: 4rem

            h2
              margin-top: 0

            p
              margin-bottom: 2rem
              display: flex
              align-items: center

              .title-badge
                margin-left: 1rem

            label
              margin: 1rem 0 2rem

              .label-after
                display: flex

            select
              width: 100%

            textarea
              display: block

            .button
              p
                margin-bottom: 0

            label.checkbox
              margin-right: 2rem

            .blockinfo
              p:last-child
                margin-bottom: 0

            .section-divider
              height: 1px
              border: 0
              margin: 4.5rem auto 4rem
              background-color: $gray-border

            .divider
              height: 1px
              border: 0
              margin: 1.5rem auto
              background-color: $gray-border

            .visualTypeTabs
              display: flex

              & > *
                margin: 0 .3rem

            .documentTypeTabs
              display: flex

              & > *
                margin: 0 .3rem

            .staging-tooltip-wrapper
              margin-left: 1rem

            .select-visuals-instruction
              a
                font-weight: bold

            .feedback-wrapper.virtual-visit
              position: relative
              margin: 4rem 0 3rem
              padding: 0

              .rating-visuals
                margin-bottom: 0
                padding-bottom: 0

              .rating-creative
                margin-top: 0
                padding-top: 0

              .rating-platform
                margin-top: 0
                padding-top: 0

            .tooltip-content

              h4
                font-size: 1.6rem
                margin: .5rem 0 1.5rem

              .total-before-discount
                display: block
                color: $gray-text
                margin-bottom: .5rem
                text-decoration: line-through

              .total
                display: block
                font-size: 1.6rem
                margin-bottom: 1.5rem

              .vat
                display: flex
                flex-direction: column

              strong
                margin-bottom: 1rem
                white-space: nowrap

              .nowrap
                white-space: nowrap

            .select-visuals-instruction
              a
                font-weight: bold

            .feedback-wrapper.virtual-visit
              position: relative
              margin: 4rem 0 3rem
              padding: 0

              .rating-visuals
                margin-bottom: 0
                padding-bottom: 0

              .rating-creative
                margin-top: 0
                padding-top: 0

            .tooltip-content
              background: red !important

              h4
                font-size: 1.6rem
                margin: .5rem 0 1.5rem

              .total-before-discount
                display: block
                color: $gray-text
                margin-bottom: .5rem
                text-decoration: line-through

              .total
                display: block
                font-size: 1.6rem
                margin-bottom: 1.5rem

              .vat
                display: flex
                flex-direction: column

              strong
                margin-bottom: 1rem
                white-space: nowrap

              .nowrap
                white-space: nowrap

        .spinner
          margin: 0 auto

  .gallery-lightbox-modal
    .gallery-lightbox-modal-content
      width: calc(100% - 2rem)
      height: calc(100% - 2rem)

      .inside
        display: flex
        flex-direction: column
        height: 100%
        padding: 1rem
        box-sizing: border-box
        background: $gray-light-text
        transition: background .15s ease

        .info-header
          position: relative
          display: flex
          align-items: baseline
          height: 2.6rem
          padding-bottom: 1rem
          box-sizing: border-box

          strong
            font-size: 1.4rem

          span
            margin-left: 1rem

        .image-box
          position: relative
          width: 100%
          height: 100%
          background-position: center center
          background-size: contain
          background-repeat: no-repeat
          box-sizing: border-box
          transition: background .15s ease

          .arrow
            position: absolute
            top: 50%
            transform: translateY(-50%)

            .icon
              height: 4rem
              max-width: none
              // fill: $gray-text
              fill: $white
              transition: fill .3s ease
              filter: drop-shadow(0 0 .7rem 0 $gray-shadow)

            &:hover
              .icon
                fill: $secondary-orange-dark

            &.previous
              left: 2rem

            &.next
              right: 2rem

          .actionbutton
            position: absolute
            top: 4rem
            right: 4rem
            box-shadow: $secondary-card-shadow

            &.check
              // background-color: adjust-color($black, $alpha: -0.5)
              background-color: $white

              // &:checked
              //   background-color: $black

          .error
            display: block
            width: 100%
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            color: $secondary-red-dark
            font-size: 1.8rem
            text-align: center

    &.selected
      .gallery-lightbox-modal-content
        .inside
          background: $secondary-orange-light

    &.purchased
      .gallery-lightbox-modal-content
        .inside
          background: $secondary-green-light

    &.selected.purchased
      .gallery-lightbox-modal-content
        .inside
          background: $secondary-green-light
          background: linear-gradient(315deg, $secondary-orange-light 0%, $secondary-green-light 100%)

  .gallery-preloader-cache
    z-index: 0
    position: absolute
    top: -1000rem
    left: -1000rem
    width: 0
    height: 0

    img
      position: absolute
      width: 0
      height: 0

  .av-payment-success
    display: flex
    flex-direction: column
    align-items: center
    gap: 1.5rem

    .av-payment-success-icon
      font-size: 5rem
      // visual centering
      margin-right: -1rem

    .av-payment-success-title
      margin: 0

    .av-payment-success-text
      margin: 0
      margin-bottom: 2rem
      font-size: 1.4rem

  .tooltip-content
    h4
      font-size: 1.6rem
      margin: .5rem 0 1.5rem

    .total-before-discount
      display: block
      color: $gray-text
      margin-bottom: .5rem
      text-decoration: line-through

    .total
      display: block
      font-size: 1.6rem
      margin-bottom: 1.5rem

    .vat
      display: flex
      flex-direction: column

    strong
      margin-bottom: 1rem
      white-space: nowrap

    .nowrap
      white-space: nowrap

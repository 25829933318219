@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .controlPanelMobileContainer
    width: 100%
    position: fixed
    bottom: 0
    z-index: 1000
    margin-bottom: 2.4rem

  .controlPanelMobileButton
    display: block
    margin: auto

    .icon
      margin-right: .5rem
      margin-top: .1rem

  .controlPanel
    display: block
    height: calc( 100vh - 4.8rem )
    background: $white
    box-shadow: 0 2rem 4.4rem #13181D
    padding: 0 1.6rem 2.4rem 1.6rem
    z-index: 10
    box-sizing: border-box
    overflow: overlay
    width: 100%
    border-radius: 2rem 2rem 0rem 0rem
    bottom: 0

    .headerContainer
      position: fixed
      width: 100vw
      height: 2.8rem
      z-index: 1001
      background-color: $white
      border-radius: 2rem 2rem 0rem 0rem
      left: 0
      padding: 2.4rem 0

      .header
        display: flex
        padding: 0 1.6rem

        .title
          font-size: 2rem
          color: $gray-900
          font-weight: 600
          line-height: 150%

        .closeButton
          margin-top: .2rem
          border: 1px solid $gray-200
          border-radius: 4rem
          width: 5rem
          height: 5rem

    .children
      margin-top: 9.4rem

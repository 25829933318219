@import 'styles/variables'

.manageAssignmentsPopup

  .subtitle
    color: $black
    font-weight: 400
    margin-top: 0

  .modalContent
    position: relative
    width: 80rem
    height: 100%
    padding: 4rem
    box-sizing: border-box

    .inside
      display: flex
      flex-direction: column
      height: 100%
      box-sizing: border-box

      h3.title
        font-size: 2.2rem
        font-weight: normal
        margin: 0 0 1rem

      .currentAssignment
        margin-top: 2rem
        margin-bottom: 3rem

      .assignmentList
        position: relative
        border-top: 1px solid transparent
        padding-bottom: 4rem

        .divider
          display: flex
          align-items: center
          justify-content: space-between
          padding: 2.3rem 0 1.7rem 0

          &:before
            content: ''
            display: inline-block
            height: .1rem
            width: 2.4rem
            background-color: $gray-border

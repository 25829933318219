@import 'styles/redesign-variables'

.editorOptionWatermark
  padding: 1.6rem 0rem

  .sizeButtonContainer
    display: grid
    grid-template-columns: repeat(3, minmax(0, auto))
    grid-gap: .8rem
    width: fit-content

  .sizeButton
    color: $gray-600 !important
    font-size: 1.6rem !important
    border: 1px solid $gray-400 !important
    padding: .8rem 1.2rem !important
    border-radius: .8rem !important
    width: fit-content

    &.selected
      color: $blue-600 !important
      border: 1px solid $blue-600 !important
      background: $blue-100 !important

  .opacity
    display: inline-block
    position: relative

    input
      border: 1px solid $gray-400 !important
      border-radius: .8rem
      height: 4rem
      max-width: 5.7rem
      padding: .8rem .4rem .8rem .8rem !important
      font-size: 1.6rem
      color: $gray-900

      &:hover
        border: 1px solid $blue-600 !important

    &::after
      content: "%"
      font-size: 1.6rem
      font-weight: 500
      margin-left: .5rem

    &:hover::after,
    &:focus-within::after
      right: 2rem

@import 'styles/variables'
@import 'styles/redesign-variables'

.footerContent
  height: 100%
  display: flex
  justify-content: flex-end
  align-items: center
  padding: 0 2.4rem

  .previewDisabled
    background-color: #B3B3B3 !important
    border-color: #B3B3B3 !important

  .processPreviewError
    color: $coral-600
    margin-right: 1rem

  .actionButton
    margin-right: 0.8rem

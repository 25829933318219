@import 'styles/variables'

html[bkbn-style=old]
  .wrap
    display: flex
    flex-flow: column nowrap
    align-items: flex-start
    justify-content: center
    box-sizing: border-box
    padding: 4rem
    gap: 1rem
    pointer-events: none

    &.active
      pointer-events: all

    &.isMobile
      flex-flow: row-reverse wrap
      gap: 4rem
      height: 0
      padding: 0 2rem

      &.active
        height: auto

      p.bottomText
        margin: 0
        margin-top: .5rem

      button.restartButton
        position: relative
        bottom: unset

      h1
        font-size: 2rem

      h3
        font-size: 1.8rem

      .left, .right
        box-sizing: border-box
        display: flex
        flex-flow: column nowrap
        justify-content: center
        height: 100%
        position: relative
        transform: translateY(0)

      .left
        flex: 1 1 10px

      .right
        flex: 0 0 auto

    span.bigEmoji
      display: block
      margin-bottom: .5rem
      margin-left: .25rem
      font-size: 4rem
      transform: scale(1) translateY(0)

      &.jump
        animation-name: jump
        animation-iteration-count: infinite
        animation-direction: alternate
        animation-duration: 450ms
        animation-timing-function: cubic-bezier(.31,.85,.46,.97)

      &.thumbsUp
        animation-name: thumbsUp
        animation-duration: 500ms
        animation-delay: 1000ms
        transform-origin: center left
        animation-timing-function: ease-in-out

    h1
      color: $gray-dark-text
      margin: 0

    h3
      color: $gray-dark-text
      font-weight: 500
      margin: 0
      font-size: 2rem
      line-height: normal

    p
      font-size: 1.6rem
      color: $gray-dark-text

    button.restartButton
      position: absolute
      bottom: 4rem
      color: $gray-text

    p.bottomText
      font-size: 1.2rem
      padding-right: 4rem

  // -- ANIMATIONS -- //
  // TODO: Create animation component Wrappers
  @keyframes jump
    0%
      transform: scale(1) translateY(0)
    100%
      transform: scale(1.2) translateY(-10px)

  @keyframes thumbsUp
    0%
      transform: rotate(0) translateY(0)
    50%
      transform: rotate(-20deg) translateY(-10px)
    80%
      transform: rotate(5deg) translateY(5px)
    100%
      transform: rotate(0) translateY(0)

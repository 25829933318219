@import 'styles/redesign-variables'

.selectableGrid

  .selectableGridButton
    border-radius: 0.8rem
    padding: 0.8rem 1.6rem
    border: 1px solid $gray-400
    background-color: $white
    margin: 0 0 0.8rem 0.8rem

    &:hover
      background-color: lighten($blue-100, 3)
      border: 1px solid $blue-600

    &.active
      background-color: $blue-100
      border: 1px solid $blue-600

      &.lockedActiveButton
        cursor: default
        pointer-events: none

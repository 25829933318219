@import 'styles/variables'

.wrapper
  display: flex
  flex-direction: row
  align-items: center
  gap: 2rem

  .content
    flex: 1 1 auto

    .type
      margin-bottom: 1rem
      font-size: 1.4rem
      font-weight: bold
      color: $black

    .info
      display: flex
      flex-flow: row wrap
      gap: 1.5rem
      font-size: 1.4rem

  .actions
    flex: 0 0 auto
    display: flex
    align-items: center
    gap: 1rem
    max-height: 4rem
    overflow: hidden

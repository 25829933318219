@import 'styles/redesign-variables'
@import 'styles/variables'

.galleryHeader
  .title
    color: $gray-900
    font-size: 4rem
    font-weight: 400
    font-family: $ff-sangbleu

    &.mobile
      font-size: 3rem

  .subTitle
    color: $gray-700
    font-size: 2rem
    margin-top: .4rem

    &.mobile
      font-size: 1.8rem

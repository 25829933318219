@import 'styles/redesign-variables'

.searchWrap
  margin: 2rem 0 3rem

  .searchBox
    width: 100%
    display: flex
    flex-direction: column
    position: relative

    input
      width: 100%
      border-radius: .8rem
      padding-right: 3.4rem

      &::-webkit-search-cancel-button
        display: none

      &::placeholder
        font-size: 1.4rem
        color: $gray-600
        font-weight: 400
        opacity: 100%

    .searchButton
      position: absolute
      align-self: flex-end
      top: 0
      bottom: 0
      margin: auto 0
      padding: 1rem 1rem 1rem 0
      border-width: 0 !important

      .searchIcon
        color: $gray-800

@import 'styles/redesign-variables'

.productLine
  display: flex
  justify-content: space-between
  font-size: 1.4rem
  font-weight: 600
  color: $gray-900
  margin-bottom: .4rem
  line-height: 1.8rem

  .additional
    color: $gray-700
    font-size: 1.2rem
    font-weight: 400
    margin-left: 1.6rem

    &::before
      content: "\A"
      width: .6rem
      height: .6rem
      border-radius: 50%
      background: $beige-700
      display: inline-block
      margin: 0 .4rem .1rem 0


.visualSelectionStep

  .header
    margin-bottom: 4rem

    h2
      margin: 0
      font-size: 3rem

    p
      line-height: normal
      font-size: 1.4rem

  .loading
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .imageGrid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr))
    gap: 1rem
    justify-content: start

    .image
      position: relative
      height: 20rem
      overflow: hidden
      width: 100% !important

.backdrop
  position: fixed
  // Elevate above TopBar
  z-index: 100
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.3)

  iframe
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 40rem
    max-width: 95vw
    height: 70rem
    max-height: 85vh
    border: none
    border-radius: 8px

  .loading
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

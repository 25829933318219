@import 'styles/variables.sass'

html[bkbn-style="old"]

  .tab-link-wrap
    text-decoration: none
    color: $gray-text

    &.is-disabled
      cursor: default

    &.is-selected

      .tab-link
        border: .3rem solid $black
        padding: 1.5rem 1.5rem

    &:hover
      .tab-link
        background-color: $gray-background

    .tab-link
      box-sizing: border-box
      display: flex
      flex-flow: column nowrap
      justify-content: space-between
      align-items: flex-start
      height: 100%
      padding: 1.7rem 1.7rem
      border: .1rem solid $gray-border
      overflow: hidden
      background-color: transparent
      transition: background-color .3s ease

      &.is-unavailable
        background-color: $gray-background

      .top-wrap
        width: 100%

        .title
          display: flex
          flex-flow: row wrap
          justify-content: space-between
          align-items: center

          .title-text
            flex: 1 0 auto
            margin: 0 0 .5rem
            overflow: hidden

          .title-text-id
            margin: 0 0 .5rem
            font-size: 1.2rem

            .text-id
              color: $black
              font-weight: bold

          .crm-link
            flex: 1 1 auto
            margin: 0 0 .5rem
            overflow: hidden
            cursor: pointer
            color: inherit
            text-overflow: ellipsis
            white-space: nowrap
            word-break: keep-all
            font-size: 1.2rem

            .link
              text-decoration: underline
              color: $black

              &:hover
                color: $secondary-orange-dark

          .title-icon-wrap
            flex: 0 0 auto
            margin-left: 1rem
            align-self: flex-start

            .title-icon
              font-size: 1.5rem

        .products
          margin: 0
          margin-bottom: 1.5rem

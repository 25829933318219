.eventWrapper
  width: 100%
  padding-top: 1rem
  list-style-type: none
  position: relative
  border-left: .2rem solid black

  &:before
    content: ''
    position: absolute
    left: 0
    top: 1.3rem
    transform: translateX(-.6rem)
    width: 1rem
    height: 1rem
    border-radius: 50%
    background: black
    z-index: 1

  .eventDate, .eventText
    display: block
    margin-left: 2rem
    padding-bottom: .5rem

  .eventDate
    font-weight: bold

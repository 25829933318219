@import 'styles/variables'

.heading
  font-size: 2.2rem
  font-weight: normal
  margin: 0 0 1rem

.line
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  font-size: 1.4rem
  margin: .5rem 0 1rem
  opacity: 1

  @media screen and (max-width: 600px)
    flex-direction: column
    align-items: flex-start

  &.badges
    justify-content: flex-start

    .creativeBadge
      @media screen and (max-width: 600px)
        margin-bottom: .5rem

.midline
  display: flex
  align-items: center
  justify-content: space-between
  padding: 1rem 0 2rem

  &:before
    content: ''
    display: inline-block
    height: .1rem
    width: 2.4rem
    background-color: $gray-border

.nameWrapper
  @media screen and (max-width: 600px)
    margin-bottom: 1rem

.total
  display: inline-block

  @media screen and (max-width: 600px)
    margin-left: 0
    margin-bottom: .5rem

.marginTopLarge
  margin-top: 2rem

.marginTop
  margin-top: 1rem

.marginBottomLarge
  margin-bottom: 2rem

.marginBottom
  margin-bottom: 1rem

.marginRight
  margin-right: 1rem

.marginVertical
  margin: 1.5rem 0 1rem

.blockinfo
  margin-bottom: 1rem

.black
  color: $black

.title
  font-size: 1.6rem

.detail
  font-size: 1.2rem
  margin-bottom: .5rem

  .contactWrapper
    display: flex
    align-items: center

    @media screen and (max-width: 600px)
      flex-direction: column
      align-items: flex-start

  .group
    display: flex
    align-items: center
    box-sizing: border-box

    @media screen and (max-width: 600px)
      display: inline-block

    .separateLeft
      display: inline-flex

      &:before
        display: inline-block
        margin: 0 .5rem
        margin-left: 1rem
        margin-top: -.12rem
        content: '•'
        color: $secondary-orange-dark

        @media screen and (max-width: 600px)
          display: none

  span,
  strong,
  a
    display: inline-flex
    color: inherit
    text-decoration: none
    height: 1.15em
    transition: color .3s ease

    a:hover
      color: $secondary-orange-dark

    .smallIcon
      margin: 0 .5rem
      vertical-align: text-bottom
      height: 1.6rem

      &.mobileonly
        display: none

        @media screen and (max-width: 800px)
          display: inline-flex

.buttonsWrapper
  display: flex
  justify-content: flex-start

  @media screen and (max-width: 600px)
    flex-direction: column
    align-items: flex-start

  .book
    @media screen and (max-width: 600px)
      margin-bottom: 1rem

.searchedCreativeWrap
  min-height: 35rem

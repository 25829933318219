@import 'styles/redesign-variables'

.downloadPopup
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 45rem
  position: relative

  .downloadLogo
    margin-bottom: 1.6rem
    background: linear-gradient(180deg, #FCF0ED 0%, rgba(252, 240, 237, 0) 100%)
    height: 5.6rem
    width: 5.6rem
    margin-left: auto
    margin-right: auto
    border-radius: 50%

    .innerCircle
      background: linear-gradient(180.88deg, #FAE4DE 0.76%, rgba(250, 228, 222, 0) 80.8%)
      height: 4rem
      width: 4rem
      margin: .8rem
      display: inline-block
      border-radius: 50%

    .img
      height: 2.2rem
      width: 2.2rem
      color: $coral-400
      font-size: 2.2rem
      margin: auto
      margin-top: .8rem

  .title
    font-weight: 600
    font-size: 2rem
    line-height: 1.5
    color: $gray-900

  .description
    color: $gray-700
    font-size: 1.6rem
    font-weight: 400
    text-align: center
    margin: .8rem 0 2.4rem

  .buttons
    display: flex
    justify-content: space-between
    width: 100%

    .button
      font-size: 1.4rem
      font-weight: 600

@import 'styles/redesign-variables'

.orderDetail

  .title
    font-size: 1.6rem
    font-weight: 600
    color: $gray-900
    line-height: 2.4rem
    margin-bottom: 0.4rem

  .date
    font-size: 1.4rem
    font-weight: 400
    color: $gray-700
    line-height: 1.96rem

  .stage
    width: 10rem
    height: 1rem
    background-color: $gray-300
    margin-right: 0.2rem
    border-radius: 10rem

    &.done
      background-color: $green-600

    &.inProgress
      background-color: $orange-600

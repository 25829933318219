@import 'styles/variables'

.preferredPaymentMethodSelect
  position: relative

  .selectWrapper
    position: relative
    display: flex
    gap: 1rem
    align-items: center
    overflow: visible

    .successIcon
      flex: 0 0 2rem
      font-size: 2rem

    .loading
      flex: 0 0 2rem
      width: 2rem !important
      height: 2rem !important

    .saveButton
      flex: 0 0 auto
      width: auto !important

  .error
    position: absolute
    bottom: -1.5rem
    color: $secondary-red-dark
    margin: 0

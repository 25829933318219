@import 'styles/redesign-variables'

.page
  width: 100%
  height: 100vh
  display: flex
  flex-flow: column nowrap
  gap: 3rem
  align-items: center
  justify-content: center
  background: $beige-500
  text-align: center

  .gif
    height: 30%
    max-height: 350px
    margin-bottom: 4rem
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1))

  h2, h3
    width: 100%
    margin: 0

  h2
    font-size: 2.5rem !important
    font-weight: 500

  h3
    margin-top: -1.5rem
    font-weight: normal
    font-size: 2rem !important

@import 'styles/variables'

.integerCard

  .header
    display: flex
    align-items: center
    gap: 2rem

    .number-input
      width: 8rem

    .content
      flex: 1 1 auto

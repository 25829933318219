@import 'styles/variables'

html[bkbn-style="old"]

  .dropdown
    display: inline-block
    box-sizing: border-box
    position: relative

    .button
      .icon
        transition: transform .3s ease
        transform-origin: center

        &.caret.up
          transform: rotate(-180deg)

        &.threedots.up
          transform: rotate(-90deg)

      & > .icon
        &:only-child.caret.up
          transform: translate(-50%, -50%) rotate(-180deg)

        &:only-child.threedots.up
          transform: translate(-50%, -50%) rotate(-90deg)

      > span + .icon
        margin-left: 2rem

    &.left-attached
      .inner-menu
        left: 0

    &.right-attached
      .inner-menu
        right: 0

      .button
        .icon
          &.caret.up
            transform: rotate(180deg)

          &.threedots.up
            transform: rotate(90deg)

        & > .icon
          &:only-child.caret.up
            transform: translate(-50%, -50%) rotate(180deg)

          &:only-child.threedots.up
            transform: translate(-50%, -50%) rotate(90deg)

    .inner-menu
      position: absolute
      top: calc(100% + .8rem)
      min-width: 25rem
      background-color: $white
      box-shadow: $secondary-card-shadow
      overflow: hidden
      display: flex
      flex-direction: column
      z-index: 1

      & > *
        padding: 1rem 0
        margin: 0 1rem
        border-bottom: 1px solid $gray-border
        will-change: transform

        &:last-child
          border-bottom: 0

      & > a
        font-size: 1.4rem
        text-decoration: none
        color: $gray-text
        transition: border-color .3s ease

        .icon
          fill: $gray-text
          height: 2rem
          margin-right: .6rem
          transition: fill .3s ease
          vertical-align: text-top

        &:hover
          cursor: pointer
          border-color: $secondary-orange-light

          .icon
            fill: $black

      & > button
        padding: 1rem .5rem
        text-align: left
        appearance: none
        border: none
        border-bottom: 1px solid $gray-border
        background: transparent
        cursor: pointer
        color: $gray-text
        font-size: 1.4rem
        white-space: nowrap
        transition: color .3s ease, border-color .3s ease, font-weight .3s ease

        &:last-child
          border-bottom: none

        &:hover
          color: $black
          border-color: $secondary-orange-light

        &.selected
          color: black
          font-weight: bold

        &:disabled,
        &[aria-disabled="true"]
          cursor: not-allowed

@import 'styles/variables'
@import 'styles/theme-variables.sass'

html[bkbn-style="old"]

  .buttonsWrapper

    .button
      font-family: $font-family
      font-size: $font-size
      display: flex
      border: 1px solid $CONTRAST_PAPER_WHITE
      height: 2.2rem
      padding: 0 1.5rem
      margin-right: 2rem

      @media screen and (max-width: 800px)
        align-items: center
        margin: 1rem 0 1rem 1rem

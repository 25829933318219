@import 'styles/redesign-variables'

.pageContent
  box-sizing: border-box
  width: 100vw
  padding: 0 5rem
  position: relative !important
  transform: unset !important

  &.mobile
    padding: 0 2rem

  .container
    max-width: 113rem
    margin: auto
    transform: unset !important

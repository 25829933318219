@import 'styles/variables'

html[bkbn-style="old"]

  .progress-card
    display: flex
    flex-direction: column
    box-sizing: border-box
    font-size: 1.4rem
    border: 1px solid $gray-border
    padding: 2rem
    transition: border-color .3s ease

    .title
      font-size: 1.6rem
      color: $black
      margin: 0 0 1rem

    progress[value]
      margin: 1rem 0 0
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      border: none
      width: 100%
      height: .2rem
      background-color: $gray-border
      color: $black
      transition: color .3s ease

      &::-webkit-progress-bar
        background-color: $gray-border

      &::-webkit-progress-value
        background-color: $black
        transition: background-color .3s ease, width .15s ease

      &::-moz-progress-bar
        background-color: $black
        transition: background-color .3s ease, width .15s ease

    .margin-bottom
      margin-bottom: .5rem

    .margin-top
      margin-top: 1rem

    .above, .bottom
      display: flex
      justify-content: space-between
      color: $gray-text
      transition: color .3s ease

      .right
        transition: color .3s ease
        cursor: help

        .line-through
          color: $gray-text
          text-decoration: line-through

        .margin-right
          margin-right: 1rem

        .bold
          color: $black
          font-weight: bold

        .tooltip-icon
          margin-left: .5rem

    &.highlighted
      border: 1px solid $secondary-orange-dark

      progress[value]
        color: $secondary-orange-dark

        &::-webkit-progress-value
          background-color: $secondary-orange-dark

        &::-moz-progress-bar
          background-color: $secondary-orange-dark

      .bottom
        color: $secondary-orange-dark

        .right
          color: $secondary-orange-dark

      &.green
        border: 1px solid $secondary-green-dark

        progress[value]
          color: $secondary-green-dark

          &::-webkit-progress-value
            background-color: $secondary-green-dark

          &::-moz-progress-bar
            background-color: $secondary-green-dark

        .bottom
          color: $secondary-green-dark

          .right
            color: $secondary-green-dark

      &.blue
        border: 1px solid $secondary-blue-dark

        progress[value]
          color: $secondary-blue-dark

          &::-webkit-progress-value
            background-color: $secondary-blue-dark

          &::-moz-progress-bar
            background-color: $secondary-blue-dark

        .bottom
          color: $secondary-blue-dark

          .right
            color: $secondary-blue-dark

    .green
      color: $secondary-green-dark

@import 'styles/redesign-variables.sass'

.wrapper
  width: 100%
  padding: 2.4rem
  overflow: hidden
  position: relative

  box-sizing: border-box
  border-radius: 0.8rem
  border: 1px solid $gray-300

  @media screen and (max-width: 600px)
    padding: 2rem

  span
    color: $gray-700
    font-weight: 500
    font-size: 1.4rem

  &:hover
    .actionsWrapper
      opacity: 1

  .progressBar
    left: 0
    bottom: 0
    width: 100%
    position: absolute

  .actionsWrapper
    display: flex

    top: 0
    right: 0
    bottom: 0
    height: 100%
    box-sizing: border-box
    position: absolute
    padding: 1rem 2rem 1rem 4rem

    gap: .5rem
    flex-flow: row wrap
    align-items: center
    justify-content: space-around

    background: linear-gradient(to left, $white 80%, transparentize($white, 0.4), transparent)
    opacity: 0

    transition: opacity .15s ease .1s

@import 'styles/variables'

.sepaDetailFields
  display: flex
  flex-flow: column
  gap: 2rem

  .countrySelect
    wifth: 100%

    .dropdown
      display: block

      \:global(.inner-menu)
        width: 100%

        .option
          &.isSelected
            pointer-events: none
            font-weight: bold
            color: $black

      \:global(.dropdown-button)

        .dropdownButton
          display: block
          width: 100%

          \:global(.textandicon-wrap)
            justify-content: space-between

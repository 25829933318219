@import 'styles/redesign-variables'

.contentWrapper
  width: 100%
  height: calc(100vh - 7rem)
  position: absolute
  background: $beige-300

  top: 7rem
  left: 0
  right: 0
  bottom: 0

  overflow: hidden
  box-sizing: border-box

  &.isMobile
    height: calc(100vh - 7rem - 6rem)

  h3
    color: $gray-900
    font-size: 1.8rem

  h4
    color: $gray-900
    font-size: 1.6rem

  .icon
    color: $coral-600

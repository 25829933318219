@import 'styles/variables'

.section
  margin-bottom: 4rem
  padding: 2rem
  border: 1px solid $gray-disabled
  border-radius: 6px

  &.noMargin

    .header
      margin-bottom: 0

  .header
    display: flex
    flex-flow: row nowrap
    justify-content: space-between
    align-items: center
    margin-bottom: 2rem

  .sectionTitle
    // specificity issue
    margin: 0 !important

@import 'styles/variables'

html[bkbn-style="old"]

  .page.index
    font-size: calc(100% * 10 / 12)
    font-family: $ff-sangbleu
    color: $new-black

    .page-content
      max-width: none

      .wrap
        position: relative
        display: flex
        align-items: center
        min-height: calc(100vh - 8rem - 10rem)

        @media screen and (max-width: 800px)
          min-height: calc(100vh - 7rem - 12rem)

        .content
          display: flex
          flex-direction: column
          align-items: flex-start

          h1
            font-size: 5em
            font-weight: normal
            margin: 0 0 calc(2em / 5)
            color: $new-black

            @media screen and (max-width: 800px)
              font-size: 4em

          h2
            font-size: 5em
            font-weight: normal
            margin: 0 0 calc(2em / 5) calc(-1em / 5)
            display: inline-block
            padding: calc(0.3em / 5) calc(1em / 5)
            color: $new-black

            @media screen and (max-width: 800px)
              font-size: 4em

          p.black
            color: $new-black
            font-size: 1.8em
            margin: calc(2em / 1.8) 0

          .button
            font-family: $ff-sailec
            line-height: 1

            &.primary
              background-color: $new-black

            &:hover
              background-color: $new-orange
              border-color: $new-orange
              color: $new-white

          .login-buttons
            display: flex
            align-items: center
            flex-wrap: wrap

            &.quote
              margin-top: 2em
              font-family: $ff-sailec
              line-height: 1

            *
              margin: calc(2em / 1.8) calc(2em / 1.8) 0 0
              white-space: nowrap

            .button
              font-size: 1.8em
              height: calc(6em / 1.8)
              padding: calc(1.8em / 1.8) calc(2.8em / 1.8)

            span
              display: inline-block
              color: $new-black
              font-size: 1.8em

            a
              text-decoration: none

          a.lost-password
            display: inline-block
            font-size: 1.8em
            text-decoration: none
            color: $new-orange
            border-bottom: .1rem solid transparent
            transition: border .15s ease

            &:hover
              border-color: $new-orange

      .decoration
        position: absolute
        top: 0
        right: 18em
        width: 24em
        background-color: $new-orange
        display: flex
        overflow: visible

        @media screen and (max-width: 1400px)
          right: 4.5em

        @media screen and (max-width: 1200px)
          right: 12em

        @media screen and (max-width: 800px)
          position: static
          top: auto
          right: auto
          display: none

        @media screen and (min-width: 2200px)
          right: 14em

        h3
          display: inline-block
          margin-top: .25em
          margin-bottom: .25em
          margin-left: calc(-3.5em / 5)
          flex-shrink: 0
          font-family: $ff-sangbleu
          font-weight: normal
          font-size: 5em
          text-align: right
          color: $new-black
          white-space: pre-line

  .blocked-user-popup

    .blocked-user-popup-modal-content
      position: relative
      width: auto
      min-height: 10rem
      padding: 4rem
      box-sizing: border-box

      .inside
        display: flex
        flex-direction: column
        height: 100%
        box-sizing: border-box

        .line
          display: flex
          flex-wrap: wrap
          align-items: center
          font-size: 1.4rem

@import 'styles/redesign-variables'

.modal
  width: 90%
  max-width: 70rem
  padding: 2rem

  .contactLink
    text-decoration: none
    color: $gray-900

// colors

// primary
$white: #FFFFFF
$black: #000000
$primary-blue: #EDF7FD
$primary-orange: #FFF4F0
$primary-green: #F4F9F4
$primary-red: #FFF9F9

// secondary
$secondary-blue-light: #BBE1F7
$secondary-blue-dark: #48ADEA
$secondary-orange-light: #FADCC6
$secondary-orange-dark: #FDA54F
$secondary-green-light: #D5EDD5
$secondary-green-dark: #77CD77
$secondary-red-light: #FFE8E8
$secondary-red-dark: #F96060

// Gray
$gray-background: #FAFAFA
$gray-light-background: #FDFDFD
$gray-border: #EDEDED
$gray-secondary: #C2C2C2
$gray-light-text: #DADADA
$gray-text: #6E6E6E
$gray-dark-text: #313131
$gray-disabled: #B3B3B3
$gray-shadow: rgba(187, 187, 187, 0.26)
$gray-light-border: #E1E1E1

// Green
$green-background: #4BAABB
$green-border: #3F9CAD

// Gallery card stages
$blue-stage: #148BD1
$blue-stage-light: #E8F5FD
$green-stage: #7BBB5D
$green-stage-light: #F0F7ED
$orange-stage: #E49E35
$orange-stage-light: #FCF4E9
$red-stage: #D24646
$red-stage-light: #FAEAEA

// Fonts

// Font definitions
@import './fonts'

// Font-family
$font-family: 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

// Text sizes

// Heading 1
$h1-size: 5rem

// Heading 2
$h2-size: 2.2rem

// Heading 3
$h3-size: 1.6rem

// Heading 4
$h4-size: 1.4rem

// Text size
$font-size: 1.2rem

// Text colors

// Headings
$heading-color: $black

// Regular text
$text-color: $gray-text

// Regular text
$description-color: $black

// Effect styles

// Primary card box shadow
$primary-card-shadow: 0 0 1.5rem 0 $gray-shadow

// Secondary card box shadow
$secondary-card-shadow: 0 0 .7rem 0 $gray-shadow

// Border radius for cards
$card-border-radius: .5rem

// NEW STYLES
$new-beige: #F2F2E8
$new-orange: #DE6C48
$new-black: #13171D
$new-white: #F3F3EA
$ff-sailec: 'Sailec', 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
$ff-sangbleu: 'SangBleu OG Sans', 'HK Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

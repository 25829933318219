.searchWrap
  margin-bottom: 2rem

  .searchBox
    width: 100%
    display: flex
    flex-direction: column

    .creativeSuggestion, .organizationSuggestion
      max-height: 40rem !important
      overflow-y: auto

  input
    width: 100%

  .line
    display: flex
    justify-content: space-between

.marginLeft
  margin-left: 1rem

.fullWidth
  width: 100%

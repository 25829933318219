@import 'styles/variables'

.assignment

  .heading
    display: flex
    align-items: center
    gap: 1.7rem
    margin-bottom: .5rem

    .title
      color: $black
      font-size: 1.6rem

    .assignmentId
      background-color: $secondary-blue-light
      display: inline-block
      color: $black
      padding: .5rem 1rem
      font-size: 1.4rem

      @media screen and (max-width: 800px)
        margin-bottom: .5rem

  .productWrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 2rem
    font-size: 1.4rem
    line-height: normal

  .dateTime
    margin-top: 1rem

    .duration
      display: inline-flex
      height: 2rem
      gap: .5rem
      padding: 0 .5rem
      background-color: $primary-blue
      font-size: 1.4rem

      .stopwatch
        height: auto
        width: 1.4rem

      .text
        display: inline-block
        line-height: 1.3

    .actions
      display: flex
      flex-wrap: wrap
      align-items: center
      gap: 1rem
      margin-top: 1.5rem

      .actionButton
        height: auto
        padding: .4rem 1rem

  .editBox
    margin-top: 1.5rem

    .inputRow
      display: flex
      align-items: flex-end
      flex-wrap: wrap
      gap: 2rem

      .input

        & > label
          display: block

    .info
      margin-top: 0.6rem

@import 'styles/variables'

.wrapperBadges
  gap: 0.5rem
  display: flex
  flex-wrap: wrap
  padding: 2rem 2rem 0 2rem

.lateSchedule
  p
    margin: 0
    font-size: 1.4rem

  h4
    margin: 0
    font-size: 1.6rem !important

  span.darker
    color: $black
    font-weight: 500

.description
  padding: 0 2rem 2rem

  h2
    margin: 1rem 0

  .textDate
    font-size: 1.6rem
    color: $gray-dark-text

.wrapperFooter
  padding: 2rem
  column-gap: 1rem
  display: flex
  flex-wrap: wrap
  align-items: center
  background: $gray-background

  button.customButton
    color: $black !important
    box-shadow: none !important
    border-color: $gray-border !important
    background-color: $gray-border !important

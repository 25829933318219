@import 'styles/redesign-variables'

.title
  color: $gray-900
  font-size: 2rem
  font-weight: 600

.subtitle
  font-size: 1.6rem
  color: $gray-700
  padding-left: .8rem

button.closeButton
  color: $gray-800
  height: 3.6rem
  width: 3.6rem
  padding: 0

@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .controlPanel
    background: $white
    box-shadow: 2px 0px 2px rgba(19, 24, 29, 0.04)
    padding: 2.4rem 1.6rem
    z-index: 10
    box-sizing: border-box
    overflow: overlay
    width: 32.8rem
    height: 100%

@import 'styles/variables'

html[bkbn-style="old"]

  .page.email-verified
    .page-content
      .wrap
        .content
          padding: 5rem
          display: flex
          flex-direction: column
          align-items: flex-start
          background-color: $white
          box-shadow: $primary-card-shadow
          border-radius: $card-border-radius

          section
            width: 100%
            margin-bottom: 3rem

            &:last-child
              margin-bottom: 0

            & > *:last-child
              margin-bottom: 0

          h1
            font-size: $h2-size
            margin: 0 auto 2rem

          p
            font-size: 1.6rem
            margin: 0 auto 2rem

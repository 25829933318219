@import 'styles/variables'

.savedPaymentMethods
  margin-top: 4rem

  .emptyTitle
    color: $gray-text
    margin-bottom: 0rem

  .emptyText
    font-size: 1.4rem
    margin-top: .5rem

  .list
    display: flex
    flex-direction: column
    gap: 1rem

  .error,
  .loading
    margin-right: 1rem

  .error
    color: $secondary-red-dark
    max-width: 25rem

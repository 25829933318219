.modal
  width: 90%
  max-width: 120rem
  padding: 2rem

  .grid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr))
    gap: 1rem
    justify-content: start

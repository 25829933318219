@import 'styles/variables'
@import 'styles/theme-variables.sass'

html[bkbn-style="old"]

  .rating-general
    box-sizing: border-box
    text-align: center
    border: .1rem solid $CONTRAST_PAPER_WHITE
    border-radius: .625rem
    padding: 1.875rem
    font-family: $ff-sailec

    .rating-general-primary-box
      border: .1rem solid $CONTRAST_PAPER_WHITE
      border-radius: .625rem

    .rating-emojis
      margin-bottom: 0

@import 'styles/redesign-variables'

.header
  gap: 1rem
  display: flex
  padding: 1rem 1rem 0
  align-items: center
  flex-direction: row
  user-select: none

  p
    margin: 0
    color: $gray-900
    font-weight: 600
    font-size: 1.4rem

  span
    color: $gray-700

  .photo
    width: 4.8rem
    height: 4.8rem
    background-color: $gray-300

.postText
  padding: 1rem

  textarea
    border-radius: 0.8rem

    &:focus
      border-color: $blue-600 !important

  p
    margin: 0
    font-size: 1.4rem
    line-height: 1.3

.footer
  display: flex
  padding: 1.2rem
  color: $gray-700
  user-select: none
  flex-direction: column

@import 'styles/redesign-variables.sass'

.divider
  margin: 2.4rem 0 !important
  border-color: $gray-300 !important
  border-radius: .1rem
  padding: unset

  &.vertical
    margin: 0 2.4rem !important

  &.\32
    margin: 0.2rem 0 !important

  &.vertical
    margin: 0 0.2rem !important

  &.\38
    margin: 0.8rem 0 !important

    &.vertical
      margin: 0 0.8rem !important

  &.\31 2
    margin: 1.2rem 0 !important

    &.vertical
      margin: 0 1.2rem !important

  &.\31 6
    margin: 1.6rem 0 !important

    &.vertical
      margin: 0 1.6rem !important

  &.\32 0
    margin: 2rem 0 !important

    &.vertical
      margin: 0 2rem !important

@import 'styles/variables'

html[bkbn-style="old"]

  .vat-number-popup
    .vat-number-popup-modal-content
      position: relative
      width: 80rem
      min-height: 30rem
      padding: 4rem
      box-sizing: border-box

      .inside
        display: flex
        flex-direction: column
        height: 100%
        box-sizing: border-box

        .blockinfo
          margin-bottom: 1rem

        .vat-box
          display: flex
          flex-direction: column
          padding: 1rem 0

          @media screen and (max-width: 800px)
            margin-bottom: 2rem
            padding: 0

          .input-row
            display: flex
            flex-wrap: wrap
            align-items: flex-end

            @media screen and (max-width: 800px)
              flex-direction: column
              align-items: flex-start

            .input
              margin-right: 2rem

              &:last-child
                margin-right: 0

              @media screen and (max-width: 800px)
                margin-bottom: 1.5rem

            .vat-number-input
              flex-grow: 1

              @media screen and (max-width: 800px)
                width: 100%

              .vat-number
                width: 100%

          // .response-wrapper
          //   padding-top: 2rem

          // .message-wrapper
          //   margin-top: 1rem

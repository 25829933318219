.showcase
  box-sizing: border-box
  display: flex
  flex-flow: row-reverse wrap
  gap: 4rem
  height: 100%
  width: 100%
  align-content: center
  justify-content: flex-start

  &.smallSample
    align-items: center
    justify-content: center
    flex-flow: column nowrap

    .image
      flex: 0 0 40%

  .image
    flex: 0 1 calc(50% - 4rem)
    box-shadow: 0px 25px 10px -20px rgba(0, 0, 0, 0.1)
    border-radius: 1rem
    aspect-ratio: 3 / 2
    background-repeat: no-repeat
    background-size: cover
    background-origin: center

@import 'styles/variables'
@import 'styles/redesign-variables'

.receiptEmail
  position: relative

  &.useStripe

    label
      font-weight: bold
      font-size: 1.6rem
      color: $black

    .receiptEmailInputWrapper

      input
        font-size: 1.6rem
        height: auto

      .saveButton
        height: 4.4rem

    .error
      position: relative
      font-size: 1.5rem
      color: $coral-600
      margin: 0
      margin-top: .5rem
      bottom: 0

  .receiptEmailInputWrapper
    position: relative
    display: flex
    gap: 1rem
    align-items: center
    overflow: visible

    input
      flex: 1 1 auto
      display: block
      width: 100%
      height: 4rem
      padding: 1rem 1.5rem

      &.isInvalid
        border: 1px solid $coral-600
        color: $coral-600

    .successIcon
      flex: 0 0 auto
      font-size: 2rem

    .loading
      flex: 0 0 auto
      width: 2rem !important
      height: 2rem !important

    .saveButton
      flex: 0 0 auto
      width: auto !important

  .error
    position: absolute
    bottom: -1.5rem
    color: $coral-600
    margin: 0

  .inputButtonWrapper
    gap: 1rem
    display: flex
    flex-direction: row
    align-items: center

    button
      margin-top: 1.2rem

  .inputWrapper
    flex: 1 1 auto

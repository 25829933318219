html[bkbn-style="old"]

  input,
  textarea
    &.autofireactioninput
      background-position: bottom 1rem right 1rem
      background-repeat: no-repeat
      background-size: auto 1.5rem

      &.ok
        background-image: url('../../../assets/img/icons/check.svg')

      &.error
        background-image: url('../../../assets/img/icons/cross.svg')

      &.running
        background-image: url('../../../assets/img/icons/loading.svg')

  input
    &.autofireactioninput
      padding-right: 3rem

@import 'styles/variables'

html[bkbn-style="old"]

  .page.loading-page
    .page-content
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      margin: auto
      min-height: calc(100vh - 8rem)

      .spinner
        margin: 0 auto

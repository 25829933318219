@import 'styles/variables'
@import 'styles/redesign-variables'

@keyframes boxShadowPulse
  0%
    box-shadow: 0 0 0 #30A3B7

  50%
    box-shadow: 0 0 17px #30A3B7

  100%
    box-shadow: 0 0 0 #30A3B7

.boxShadowPulse
  animation: boxShadowPulse 2000ms ease

.modalContent
  position: relative
  height: 88vh
  width: 80%
  max-width: 1600px
  padding: 0
  background: $beige-600
  align-items: center

  .scrollableContent
    flex: 1 1 auto
    width: 100%
    overflow: auto
    padding: 4rem
    box-sizing: border-box
    height: 100%
    position: relative
    display: flex
    flex-flow: column nowrap
    justify-content: stretch
    align-items: flex-start

    h1, h3
      margin: 0
      color: $gray-text

    .csat
      margin: 0 auto
      width: 100%
      max-width: 750px
      display: flex
      flex-flow: column
      gap: 4rem
      text-align: center

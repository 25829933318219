@import 'styles/variables'

html[bkbn-style="old"]

  .closeWrap
    position: absolute
    top: .5rem
    right: .5rem

  .closeWrapLeft
    position: absolute
    top: 1.5rem
    left: 1rem

  .closeBtnRight
    opacity: .5
    padding: 0
    height: 2.4rem
    width: 2.4rem
    position: absolute
    top: 1.2rem
    right: 1.2rem
    z-index: 10
    cursor: pointer

// theme-colors  
$BACKBONE_SAND: #F2F4EB
$DARK_BACKBONE_SAND: #DADFCA
$PAPER_WHITE: #FEFDFA
$DEEP_SPACE_BLACK: #22292E
$MEDITERRANEAN_BLUE: #4BAABB
$CONTRAST_BLUE: #3F9CAD
$DARK_ORANGE: #E06E49
$CONTRAST_ORANGE: #B84B28
$YES_GREEN: #6AC58A
$NO_RED: #E85E5E
$EVERYDAY_PEN_PURPLE: #7B61FF
$CONTRAST_BACKBONE_SAND: #EAEDDF
$CONTRAST_PAPER_WHITE: #DBDBDB
$DARK_CONTRAST_BACKBONE_SAND: #E6E6DD
$CAUTION_RED: #FDF4F1
$ACTIVATED_GREEN: #F5F9F4

// breakpoints
$xl: 1526px
$lg: 1200px
$md: 900px
$sm: 600px
$xs: 0px

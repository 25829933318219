@import 'styles/variables'

html[bkbn-style="old"]

  .button
    box-sizing: border-box
    font-size: 1.4rem
    border: 1px solid $black
    height: 4rem
    padding: .8rem 1.9rem
    cursor: pointer
    transition: background-color .15s ease, box-shadow .15s ease
    position: relative
    overflow: hidden

    &.rounded
      border-radius: 10em

    &.sharp
      border-radius: 0

    @media screen and (max-width: 800px)
      height: auto
      min-height: 4rem
      white-space: normal

    &.thin
      height: 3rem
      padding: 0 1.9rem

    &:disabled,
    &[aria-disabled="true"]
      cursor: not-allowed

    &.textandicon
      padding-left: 1.4rem
      padding-right: 1.4rem

      .textandicon-wrap
        display: flex
        align-items: center

        .icon,
        span
          display: inline-block
          margin-left: .5rem
          margin-right: .5rem

        .icon
          max-width: none
          vertical-align: unset

    &.wide
      .textandicon-wrap
        justify-content: space-between

    & > .icon:only-child,
    & > .icon.absolute
      font-size: 1.6rem
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    &.primary
      background-color: $black
      color: $white

      .icon
        fill: $white

      &:hover
        box-shadow: 0 0 .5rem 0 #bbbbbb

      &:disabled,
      &[aria-disabled="true"]
        background-color: $gray-disabled
        border-color: $gray-disabled

      &.light
        background-color: $gray-background
        border-color: $gray-background
        color: $black

      &.red
        background-color: $secondary-red-dark
        border-color: $secondary-red-dark
        color: $white

        &.light
          background-color: $primary-red
          border-color: $primary-red
          color: $black

      &.orange
        background-color: $secondary-orange-dark
        border-color: $secondary-orange-dark
        color: $white

        &.light
          background-color: $primary-orange
          border-color: $primary-orange
          color: $black

      &.blue
        background-color: $secondary-blue-dark
        border-color: $secondary-blue-dark
        color: $white

        &.light
          background-color: $primary-blue
          border-color: $primary-blue
          color: $black

      &.green
        background-color: $secondary-green-dark
        border-color: $secondary-green-dark
        color: $white

        &.light
          background-color: $primary-green
          border-color: $primary-green
          color: $black

    &.link
      height: auto
      padding: 0
      background-color: transparent
      color: $black
      font-weight: 600
      border: none

      &::after
        content: ''
        position: absolute
        bottom: 0px
        left: 0
        width: 0%
        height: 1px
        background: currentColor
        transition: width 200ms ease

      &:hover::after
        width: 100%

      .icon
        fill: currentColor

      &.orange
        color: $secondary-orange-dark

      &:disabled,
      &[aria-disabled="true"]
        color: $gray-disabled

        &:hover::after
          width: 0%

    &.secondary
      background-color: $white
      color: $black

      &:hover
        background-color: $gray-background

      &:disabled,
      &[aria-disabled="true"]
        color: $gray-disabled
        border-color: $gray-disabled

        .icon
          fill: $gray-disabled

      &.noborder
        border: 1px solid transparent

        &:hover
          border-color: transparent
          background-color: transparent

      &.nobackground
        background-color: transparent

        &:hover
          background-color: transparent

      &.red-border
        border-color: $secondary-red-dark

        &:disabled,
        &[aria-disabled="true"]
          border-color: lighten($secondary-red-dark, 20%)

      &.red
        color: $secondary-red-dark
        border-color: $secondary-red-dark

        .icon
          fill: $secondary-red-dark

        &:disabled,
        &[aria-disabled="true"]
          color: lighten($secondary-red-dark, 20%)
          border-color: lighten($secondary-red-dark, 20%)

          .icon
            fill: lighten($secondary-red-dark, 20%)

        &.noborder
          border: 1px solid transparent

      &.orange
        color: $secondary-orange-dark
        border-color: $secondary-orange-dark

        .icon
          fill: $secondary-orange-dark

        &:disabled,
        &[aria-disabled="true"]
          color: lighten($secondary-orange-dark, 20%)
          border-color: lighten($secondary-orange-dark, 20%)

          .icon
            fill: lighten($secondary-orange-dark, 20%)

        &.noborder
          border: 1px solid transparent

      &.blue
        color: $secondary-blue-dark
        border-color: $secondary-blue-dark

        .icon
          fill: $secondary-blue-dark

        &:disabled,
        &[aria-disabled="true"]
          color: lighten($secondary-blue-dark, 20%)
          border-color: lighten($secondary-blue-dark, 20%)

          .icon
            fill: lighten($secondary-blue-dark, 20%)

        &.noborder
          border: 1px solid transparent

      &.green
        color: $secondary-green-dark
        border-color: $secondary-green-dark

        .icon
          fill: $secondary-green-dark

        &:disabled,
        &[aria-disabled="true"]
          color: lighten($secondary-green-dark, 20%)
          border-color: lighten($secondary-green-dark, 20%)

          .icon
            fill: lighten($secondary-green-dark, 20%)

        &.noborder
          border: 1px solid transparent

    & + label.underlabel
      display: inline-block
      margin-top: .5rem
      font-size: 1rem
      text-align: center
      white-space: normal

    .loading
      width: 2rem !important
      height: 2rem !important
      margin: 0 3rem

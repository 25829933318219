@import 'styles/reset.css'

.appView

  .menu
    position: absolute
    top: 1rem
    left: 1rem
    bottom: 1rem
    width: 10rem
    border-radius: 16px
    background: rgba(90, 90, 90, 0.3)

  .routerView
    width: calc(100vw - 12rem)
    height: 100vh
    margin-left: 12rem
    padding-left: 3rem

    // TODO: remove after establishing page layout/scrollable component
    overflow-y: auto

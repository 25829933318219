@import 'styles/variables'

.imageStack
  position: relative
  display: flex
  flex-flow: nowrap
  align-items: center
  height: 33vw
  max-height: 20rem
  overflow-x: auto
  overflow-y: visible
  padding-top: 1rem
  padding-bottom: 2rem

.headerImage
  flex: 0 0 auto
  position: relative
  width: auto
  height: 100%
  border-radius: 6px
  border: 1px solid $gray-border
  margin: -1px // balance the border
  margin-left: calc(-1px - 2rem)

  &.upper
    transform: translateY(-1rem)

  &.lower
    transform: translateY(1rem)
    z-index: 1

  &.noLeft
    margin-left: 0

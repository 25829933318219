@import 'styles/redesign-variables'

.positionningContainer
  display: flex
  align-items: center

  .selectedPositionTitle
    font-size: 1.6rem
    font-weight: 500
    color: $gray-900
    margin-left: 2.4rem

  .positionningVisual
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    border: 1px solid $gray-300
    border-radius: .8rem
    width: fit-content
    margin-top: .6rem
    cursor: pointer
    width: 9.6rem
    height: 9.6rem

    .positionningGrid
      display: grid
      grid-template-columns: repeat(3, minmax(0, 1fr))

      .position
        display: flex
        justify-content: center
        border-radius: .6rem
        width: 3rem
        height: 3rem

        &.selected
          background: $blue-100

        .dot
          color: $gray-400
          font-size: 2rem

          &::before
            content: "•"

          &.selected
            color: $blue-600

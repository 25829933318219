@import 'styles/variables'
@import 'styles/redesign-variables'

.editorOptionButton
  font-family: $font-family
  font-weight: 600
  background-color: $blue-100 !important
  padding: 0rem 1.2rem !important

  &:hover
    background-color: $blue-200 !important
    box-shadow: 0px 0px 0px 2px rgba($blue-600, 0.3)

  &.active
    background-color: $blue-200 !important
    border: 1px solid $blue-600 !important
    cursor: default
    pointer-events: none

  .icon
    margin: .4rem .8rem 0 0 !important
    font-size: 2rem
    color: $blue-600

  &.disabled
    background-color: $gray-100 !important
    border: 1px solid $gray-600 !important

    .icon
      color: $gray-600

    &:hover
      background-color: none
      box-shadow: none

  &.actionButton
    border-radius: 0.8em !important

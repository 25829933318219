@import 'styles/variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .editorOption

    .header
      color: $gray-900
      font-size: 1.6rem
      font-weight: 600
      line-height: 2.4rem
      height: 3rem
      margin-bottom: .8rem
      display: flex
      align-items: center
      justify-content: space-between

      .titleWrap
        display: flex
        align-items: center
        justify-content: space-between

        .tooltipIcon
          font-size: 1.6rem
          margin-left: .8rem
          cursor: help

      .resetButton
        padding: unset !important
        display: none

        &.active
          display: block

        .resetIcon
          padding-top: .5rem
          font-size: 2rem
          outline-color: $white

    .hint
      font-size: 1.4rem

    .buttonWrap
      display: flex
      gap: .8rem

  .tooltip
    max-width: 48rem

  .tooltipLeft
    top: -0.5rem !important

@font-face
  font-family: 'SangBleu OG Sans'
  src: url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans.woff2') format('woff2'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans.woff') format('woff'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans.ttf') format('truetype'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans.svg#SangBleuOGSans') format('svg')
  font-weight: normal
  font-style: normal
  font-display: swap

@font-face
  font-family: 'SangBleu OG Sans'
  src: url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans-Light.woff2') format('woff2'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans-Light.woff') format('woff'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans-Light.ttf') format('truetype'), url('../../assets/fonts/sangbleu-og-sans/SangBleuOGSans-Light.svg#SangBleuOGSans-Light') format('svg')
  font-weight: 300
  font-style: normal
  font-display: swap

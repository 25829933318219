@import 'styles/redesign-variables'

.customerReviewCard
  box-sizing: border-box
  max-width: 55rem
  background-color: $beige-600
  padding: 3.2rem
  border-radius: .8rem

  .content
    font-size: 2rem
    font-weight: 400
    color: $gray-900
    line-height: 1.5
    margin-bottom: 2.4rem

  .customerWrap
    display: flex
    justify-content: flex-start
    align-items: center

    .img
      width: 6.4rem
      height: 6.4rem
      margin-right: 2rem
      flex-shrink: 0

    .customer
      .name
        color: $gray-900
        font-weight: 600
        font-size: 1.6rem
        line-height: 2.4rem
        margin-bottom: .2rem

      .title
        color: $gray-700
        font-weight: 400
        font-size: 1.4rem
        line-height: 2rem

@import 'styles/variables'
@import 'styles/theme-variables.sass'

html[bkbn-style="old"]

  .ratingText
    max-width: 100%
    display: block
    position: relative
    box-sizing: border-box
    text-align: left
    margin-top: 3.75rem

    .ratingTextWrapper
      display: flex
      flex-direction: column
      width: 100%

      .label
        margin: 1.25rem 0
        color: $gray-dark-text
        font-size: 1.4rem

      .isTextareaFocused
        color: $black

      textarea::placeholder
        font-size: 1.4rem
        opacity: 35%

      textarea
        width: 30rem
        border-radius: .625rem
        margin-bottom: 2rem

        &:disabled
          background-color: $gray-light-background

      .ratingUnhappyWarning
        margin-bottom: 3.125rem

      .buttonWrapper
        display: flex
        justify-content: flex-end
        width: 100%

        .submit
          width: 50%

      .response
        margin: 2rem

        h3
          margin: 0

          &.errorHeading
            margin-bottom: 1rem

@import 'styles/variables'

html[bkbn-style="old"]

  .breadcrumbs
    display: flex
    align-items: center
    height: 4rem
    margin: 4rem 0

    .backbutton
      margin-right: 2rem

    .texts
      display: flex
      flex-direction: column

      .title
        font-size: 1.4rem
        color: $black
        font-weight: bold
        white-space: nowrap
        margin-bottom: .25rem

      .text
        white-space: nowrap

    .arrow
      height: 4rem
      font-size: 0
      margin: 0 2rem

    .pack
      font-size: 1.4rem
      color: $black
      font-weight: bold
      white-space: nowrap

    @media screen and (max-width: 800px)
      display: none

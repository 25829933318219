@import 'styles/redesign-variables'

.editorOptionRatio
  padding: 1.6rem 0rem

  .ratioPatternWrap
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: .8rem

    .ratioPattern
      padding: 1.6rem .8rem
      display: flex
      justify-content: center
      gap: .8rem
      align-items: center
      flex-direction: column
      border: 1px solid $gray-300
      border-radius: .8rem
      cursor: pointer

      &.selected
        border: 1px solid $blue-600

      .ratioSize
        height: 5.6rem
        background: $gray-100
        border: 1px solid $gray-300
        border-radius: .6rem

      .ratioTitle
        font-size: 1.4rem
        color: $gray-900

        span
          font-size: 1.2rem
          font-weight: 400
          color: $gray-700
          margin-left: .4rem

@import 'styles/variables'

.roomTabBar
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr))
  grid-gap: 1rem
  margin-bottom: 2rem

  .roomTab
    display: flex
    flex-flow: column nowrap
    justify-content: space-between
    align-items: flex-start
    box-sizing: border-box
    border: .1rem solid $black
    height: 8rem
    min-width: 15rem
    padding: 1rem

    &.clickable
      cursor: pointer

    &.complete
      border-color: $secondary-green-dark

    &.active
      border-width: 0.2rem
      padding: 0.9rem

      .tabTitle
        font-weight: 800

    .tabTitle
      margin: 0
      font-size: 1.4rem
      font-weight: 600

@import 'styles/redesign-variables'

.noTemplateAvailable
  display: flex
  flex-direction: column

  .button
    width: fit-content
    margin-bottom: 0.8rem

  .hint
    font-size: 1.4rem

.list
  opacity: 1
  pointer-events: all
  transition: opacity 200ms

  &.isRefetching
    pointer-events: none
    animation-name: opacityPulse
    animation-duration: 1.5s
    animation-iteration-count: infinite
    animation-fill-mode: forwards

.noData
  span
    display: block
    width: 100%
    text-align: center
    font-size: 1.7rem

@keyframes opacityPulse
  0%
    opacity: 0.7

  50%
    opacity: 0.3

  100%
    opacity: 0.7

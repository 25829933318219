
.stagingStep

  .header
    margin-bottom: 4rem

    h2
      margin: 0
      font-size: 3rem

    p
      line-height: normal
      font-size: 1.4rem

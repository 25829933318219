@import 'styles/redesign-variables'

.pageLayout
  min-height: 100vh
  width: 100vw
  background-color: $beige-300
  padding-top: 6.4rem
  overflow: scroll

  .header
    box-sizing: border-box
    width: 100vw
    background-color: $beige-500
    padding: 4rem 5rem 0 5rem
    border-bottom: solid $beige-700 .1rem

    &.mobile
      padding: 2.4rem 2rem 0 2rem

    .container
      max-width: 113rem
      margin: auto

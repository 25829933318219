@import 'styles/variables'

.upsellModal

  .upsellPaymentModalContent
    position: relative
    padding: 2rem
    width: 90%
    max-width: 60rem
    transition: max-height 300ms ease

    &.isMinified
      max-height: 20rem

    .loadingBox
      margin: 0
      width: 100%
      display: flex
      flex-flow: column
      align-items: center

    .contactLink
      font-weight: bold
      text-decoration: underline

    .successWithError
      margin-bottom: 1rem

    .successBox
      display: flex
      flex-direction: column
      align-items: center
      gap: 1.5rem

      h2
        margin: 0
        margin-bottom: 2rem

      .icon
        // visual centering
        margin-right: -1rem
        font-size: 5rem

    .contentWrap
      max-height: 200rem
      opacity: 1
      transition-property: opacity, max-height
      transition-duration: 300ms
      transition-timing-function: ease

      &.hidden
        max-height: 1px
        opacity: 0
        overflow: hidden

      .header
        margin-bottom: 3rem

        .product
          margin: 0
          margin-bottom: 1rem

        .total
          margin: 0
          margin-bottom: 2rem

        .info
          margin: 0
          line-height: normal
          font-size: 1.4rem

      .payment
        margin-bottom: 4rem

        .title
          margin: 0
          margin-bottom: 2rem

        .paymentProducts
          display: flex
          flex-flow: column
          gap: 1rem

          .product

            .container
              display: flex
              justify-content: space-between
              align-items: center
              gap: 2rem

              .left

                .name
                  font-size: 1.4rem
                  font-weight: bold
                  color: $black

                .disclaimer
                  display: block
                  margin-top: .5rem
                  font-weight: 600
                  color: $secondary-orange-dark

              .right
                display: flex
                align-items: center
                flex: 0 0 auto

                .saveError
                  color: $secondary-red-dark

        .paymentWrapper
          margin-top: 4rem

      .errorBox
        margin-bottom: 2rem

      .actions
        gap: 2rem
        display: flex
        flex-flow: row
        justify-content: space-between
        align-items: center

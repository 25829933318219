@import 'styles/variables'

.paymentStatus
  height: calc(100% - 8rem)

  &.ERROR
    background: $primary-orange

  &.SUCCESS
    background: $primary-green

  &.PROCESSING
    background: $primary-blue

  \:global(.page-content)
    position: relative

    display: flex
    justify-content: center
    align-items: center

    .statusBox
      text-align: center
      margin-top: 8vh
      max-width: 50rem
      width: 100%
      display: flex
      flex-direction: column
      align-items: center

      .statusIcon
        align-self: center
        font-size: 8rem
        margin-bottom: 4rem

        &.PROCESSING
          animation: spin 3s linear infinite forwards

          @keyframes spin
            0%
              transform: rotate(0deg)

            100%
              transform: rotate(360deg)

      .title
        margin: 0
        margin-bottom: 2rem

      .text
        font-size: 1.4rem
        margin-bottom: 5rem
        line-height: normal

      .textContrast
        color: $black
        font-size: 1.6rem
        margin-bottom: 5rem
        line-height: normal

      .actions
        display: flex
        justify-content: center
        align-items: center
        gap: 1rem

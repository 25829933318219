.modalBody
  width: 90%
  max-width: 60rem
  padding: 2rem
  display: flex
  flex-direction: column
  gap: 2rem
  justify-content: flex-start
  align-items: stretch

  h2
    margin: 0

  p
    margin: 0
    font-size: 1.4rem
    line-height: normal

  .inputRow
    label
      margin-bottom: 1rem
      margin-top: 1rem
      display: block

    input
      display: block

  .footer
    .actionRow
      display: flex
      align-items: center
      justify-content: space-between
      gap: 2rem
      margin-top: 2rem

    .warning
      margin-bottom: 2rem

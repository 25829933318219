@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .editProcessingPopup
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 45rem

    .iconWrap
      display: flex
      justify-content: center
      align-items: center
      margin: 2rem

      .icon
        width: 4rem
        height: 4rem
        font-size: 2.4rem
        margin-bottom: 1rem

    .title
      font-size: 1.6rem
      font-weight: 600
      color: $gray-900
      margin-bottom: .4rem
      line-height: 1.5

    .description
      font-size: 1.6rem
      font-weight: 400
      color: $gray-700
      line-height: 1.5

    .closeBtnRight
      opacity: .5
      padding: 0
      height: 2.4rem
      width: 2.4rem
      position: absolute
      top: 1.2rem
      right: 1.2rem
      z-index: 10
      cursor: pointer

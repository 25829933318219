@import 'styles/variables'

.header
  display: flex
  align-items: center
  gap: 2rem

  .content
    display: flex
    flex-direction: column
    justify-content: center
    flex: 1 1 auto

    & > *
      margin: 0 0 .5rem 0

      &:last-child
        margin-bottom: 0

    p
      span.newline
        display: block
        margin: 0 0 .5rem 0

        &:last-child
          margin-bottom: 0

      &.disclaimer
        font-weight: 600
        color: $secondary-orange-dark

    .title,
    strong
      color: $black
      font-size: 1.4rem

    .icon
      vertical-align: baseline
      margin-right: .5rem

  &.clickable
    cursor: pointer

  &.disabled

    .rightContent
      hr
        background: $gray-text

      strong
        color: $gray-text

    .content
      .title,
      strong
        color: $gray-text

.rightContent
  display: flex
  flex-direction: column
  justify-content: center
  margin-left: auto
  text-align: right

  & > *
    margin: 0 0 .5rem 0
    white-space: nowrap

    &:last-child
      margin-bottom: 0

    &.lineThrough
      text-decoration: line-through

    &.grayText
      color: $gray-text

  hr
    border: none
    background: $black
    width: 1.5rem
    height: .2rem

  strong
    color: $black
    font-size: 1.4rem

.badges
  gap: 1rem
  display: flex
  justify-content: flex-end

  & span
    font-size: 1.2rem
    line-height: normal

@import 'styles/variables'
@import 'styles/theme-variables'

.workspaceName
  display: block
  text-align: center
  font-size: 3.5rem
  font-family: $ff-sangbleu
  color: $black

  .divider
    margin: auto
    width: 4rem
    border-bottom: 0.1rem solid $secondary-red-dark

  .hasDuplicatedName
    margin-top: 2rem
    color: $secondary-red-dark
    font-size: 1.6rem
    font-family: $font-family
    line-height: 2

  .inputWrap
    padding: 3rem 0
    display: flex
    justify-content: center
    align-items: center

    .editButton
      width: 2.8rem
      height: 2.8rem
      padding: 0
      margin-left: 1rem
      background-color: transparent
      border: 0
      cursor: pointer
      background-color: $DARK_ORANGE
      border-radius: 0.3rem
      display: flex
      justify-content: center
      align-items: center

      .editIcon
        width: 1.6rem
        height: 1.6rem
        max-width: unset

    .workspaceInput
      font-size: 3.5rem
      font-family: $ff-sangbleu
      height: 4rem
      width: 100%
      border: none
      text-align: center

.loading
  position: absolute
  top: 40vh
  left: 50%
  transform: translate(-50%, -50%)

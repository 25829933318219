@import 'styles/redesign-variables'

.assignmentStageProgress

  .status
    font-size: 1.2rem
    font-weight: 500
    line-height: 1.8rem
    margin-bottom: 0.4rem
    color: $gray-700

  .statusTitle
    font-size: 1.2rem
    font-weight: 500
    line-height: 1.8rem
    margin-bottom: 0.4rem
    color: $gray-900

    &.jobDone
      color: $gray-700

  .stage
    width: 4.85rem
    height: 0.4rem
    background-color: $gray-300
    margin-right: 0.2rem
    border-radius: 10rem

    &.done
      background-color: $green-600

    &.inProgress
      background-color: $orange-600

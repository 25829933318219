@import 'styles/variables'

html[bkbn-style="old"]

  .triangleicon
    position: relative
    display: flex
    justify-content: center
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    width: 5rem
    height: 5.2rem

    .triangle
      max-width: none
      height: 3em

    .inner-icon
      max-width: 2em
      height: 1.6em
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -75%)

@import 'styles/variables'
@import 'styles/redesign-variables.sass'

$light-colors: ('blue': $blue-stage-light, 'orange': $orange-stage-light, 'green': $green-stage-light, 'red': $red-stage-light)
$dark-colors: ('blue': $blue-stage, 'orange': $orange-stage, 'green': $green-stage, 'red': $red-stage)
$colors: 'blue', 'orange', 'green', 'red'

@each $color in $colors
  .#{$color}
    padding: 2rem
    color: $black
    background: map-get($light-colors, #{$color})
    border-color: map-get($light-colors, #{$color})

    a
      color: $black
      text-decoration: none

    .divider
      border: 0
      width: 100%
      height: 1px
      background-color: map-get($dark-colors, #{$color})

    .iconsWrapper
      gap: 3rem
      display: flex
      flex-wrap: wrap
      align-items: center

      svg
        fill: map-get($dark-colors, #{$color}) !important

    button
      height: 100% !important
      min-height: 3rem

      &.primary
        color: $white !important
        background: map-get($dark-colors, #{$color}) !important
        border-color: map-get($dark-colors, #{$color}) !important

      &.secondary
        background: transparent !important
        color: map-get($dark-colors, #{$color}) !important
        border-color: map-get($dark-colors, #{$color}) !important

      &:hover
        box-shadow: none !important

      &:disabled
        color: rgba(0, 0, 0, 0.3) !important
        border-color: rgba(0, 0, 0, 0.2) !important
        background-color: transparent !important

      &.destructive
        color: map-get($dark-colors, 'red') !important
        border-color: map-get($dark-colors, 'red') !important

      &.underlinedButton
        padding: 0
        font-weight: 600
        margin-top: -1rem
        margin-left: auto
        color: $gray-900 !important
        border-radius: unset !important
        text-decoration: underline !important

.headerWrapper
  margin-bottom: 2rem

  h2
    margin: 0

  h3
    margin: 0
    font-weight: 600
    font-size: 1.8rem
    margin-bottom: .25rem

    &.automationHeading
      margin-bottom: 1.5rem

  p
    font-size: 1.4rem
    color: $gray-dark-text

.smallHeader
  font-weight: 600
  font-size: 1.2rem

.flexWrapper
  gap: 1rem
  display: flex
  align-items: center

  &.spaceBetween
    justify-content: space-between

.columnWrapper
  gap: 1rem
  display: flex
  flex-direction: column

.sideButtonWrap
  gap: 1rem
  display: flex
  flex: 0 0 auto
  align-items: center
  justify-content: flex-end

.automatedWrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 1rem

  p
    margin: 1rem 0
    max-width: 60%
    font-size: 1.4rem
    color: $gray-dark-text

.creativeWrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between

  .information
    gap: 0.5rem
    max-width: 60%

    .textGap
      gap: .5rem

    h3
      margin: 0
      font-weight: 600
      font-size: 1.8rem
      margin-bottom: .25rem

    span
      display: inline-block
      margin-top: -3px
      font-size: 1.4rem
      color: $gray-dark-text
      word-break: break-all

.editRemunerationSection

  span
    font-size: 1.4rem

  button
    min-height: 2rem
    border-radius: 0 !important
    color: $blue-stage !important

.firstOutreach
  padding: 1.5rem
  border-radius: 4px
  border: 1px solid map-get($dark-colors, 'blue')
  display: flex
  justify-content: space-between
  align-items: stretch

  &.isCenter
    justify-content: center

  .spinner
    width: 3rem !important
    height: 3rem !important

  span.noCT
    font-size: 1.4rem

  h3
    margin: 0

  .ctInfo
    font-size: 1.3rem

    a
      gap: .5rem
      margin-bottom: .25rem

  .ctMargin
    align-self: stretch
    display: flex
    align-items: center
    color: $gray-text
    font-weight: bold
    font-size: 1.4rem

    &.positive
      color: darken(map-get($dark-colors, 'green'), 12.5%)

    &.negative
      color: map-get($dark-colors, 'red')

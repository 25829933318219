@import 'styles/redesign-variables'

.container
  display: flex
  flex-direction: column

  .previewGalleryImage
    width: 24.8rem
    height: 16.6rem
    background-position: center center
    background-size: contain
    background-repeat: no-repeat
    box-sizing: border-box
    border-radius: .8rem
    margin-bottom: 1.2rem
    background-color: $beige-600
    position: relative

  .fileName
    color: $gray-900
    font-size: 1.4rem
    font-weight: 600

  .spinner
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

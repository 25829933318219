@import 'styles/variables'
@import 'styles/theme-variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .link
    font-weight: bold
    cursor: pointer
    color: $gray-900
    text-decoration: none

    & > a
      color: $gray-900

    &:hover
      color: $gray-700

  .acceptActionPopup
    ul
      padding-left: 2.5rem
      margin-top: 1rem
      line-height: 1.3

  .acceptActionPopupTerms
    font-size: 1.25rem

  .ctAssignmentCard
    position: relative
    display: flex
    box-sizing: border-box
    width: 100%
    border-radius: $card-border-radius
    background-color: $white
    box-shadow: $primary-card-shadow
    flex-direction: column

    &:before
      content: ''
      display: block
      position: absolute
      top: 0
      right: 0
      left: 0
      width: 100%
      height: .4rem
      background-color: var(--secondary-dark)
      border-radius: $card-border-radius $card-border-radius 0 0

    &.deleted
      opacity: 0.5 !important

    &.orange
      --primary: #{$primary-orange}
      --secondary-dark: #{$secondary-orange-dark}
      --secondary-light: #{$secondary-orange-light}

    &.blue
      --primary: #{$primary-blue}
      --secondary-dark: #{$secondary-blue-dark}
      --secondary-light: #{$secondary-blue-light}

    &.green
      --primary: #{$primary-green}
      --secondary-dark: #{$secondary-green-dark}
      --secondary-light: #{$secondary-green-light}

    &.red
      --primary: #{$primary-red}
      --secondary-dark: #{$secondary-red-dark}
      --secondary-light: #{$secondary-red-light}

    .content
      display: flex
      flex-direction: column
      box-sizing: border-box
      padding: 2.5rem
      padding-bottom: 1.5rem

      @media screen and (max-width: 800px)
        padding-top: 1.5rem
        padding-bottom: 2.5rem

    .topline
      display: flex
      align-items: center
      align-content: center
      margin-bottom: 1rem

      @media screen and (max-width: 800px)
        padding-top: 1rem
        flex-direction: column-reverse

      .graytitle
        margin: 0 auto 0 0
        font-size: 1.6rem

      .right
        display: flex
        align-items: center
        gap: 1rem

        @media screen and (max-width: 800px)
          width: 100%
          justify-content: space-between
          margin-bottom: 2rem
          flex-flow: row-reverse

        .labels
          display: flex
          flex-direction: row
          gap: 1rem
          align-items: center

        .number
          display: inline-block
          color: $black
          background-color: var(--primary)
          padding: .5rem 1rem

        .dropdown
          margin-right: -1.25rem

      .dropdown .inner-menu
        z-index: 2

    .heading-wrap
      display: flex
      justify-content: flex-end
      margin: 1rem 0
      gap: 2rem

      @media screen and (max-width: 800px)
        flex-direction: column

      .heading-text
        flex: 1 1 auto

        .title
          margin: 0 0 1.5rem 0
          font-size: 2.2rem

        .subtitle
          margin: 0
          font-weight: 500

      .heading-price
        display: flex
        flex-direction: column
        align-items: flex-end
        flex: 0 0 auto

        @media screen and (max-width: 800px)
          align-items: start

        .price-wrapper
          display: inline-flex
          align-items: center

          @media screen and (max-width: 800px)
            flex-direction: row-reverse

        &.with-tooltip
          cursor: help

        .tooltip-icon
          margin-right: 1rem
          fill: var(--secondary-dark)

      .__react_component_tooltip
        min-width: 30rem
        max-width: calc(100vw - 4rem)

      .group-icons
        padding-right: 1rem
        margin: 0
        color: $black

        @media screen and (max-width: 800px)
          padding-right: 0
          padding-left: 1.5rem

        .icon
          max-width: none
          height: 1.8rem
          width: 1.8rem
          padding: 0 1rem

          &.car
            width: 2rem
            height: 2rem
            margin-bottom: -.4rem

            @media screen and (max-width: 800px)
              padding-left: 0

        .plus-sign
          font-weight: 900
          color: var(--secondary-dark)

      .transport-price
        display: flex
        flex-direction: row-reverse
        margin-top: .5rem
        padding: .5rem 0
        cursor: help

        .icon
          max-width: 1.6rem
          height: 1.6rem
          margin-right: 1rem

          @media screen and (max-width: 800px)
            margin-right: 0
            margin-left: 1rem

      .transport-cost-tooltip
        min-width: 0

      .price
        display: inline-flex
        align-items: center
        font-weight: 600
        color: $black
        font-size: 1.6rem
        padding: .8rem 2rem
        min-height: 4rem
        background-color: $white
        border: 1px solid $gray-border
        box-sizing: border-box

    .infoline
      display: flex
      align-items: center
      flex-wrap: wrap
      margin-top: 1.5rem
      gap: 2rem

      &:last-child
        margin-bottom: 1rem

      .event-time
        .time-line
          font-size: 1.2rem
          gap: .5rem

        .duration
          .icon
            margin-left: 0
            margin-top: 1px
            font-size: 1.25rem

      .more
        .button
          font-size: 1.2rem

      .fullLine
        flex: 1 1 100%

      .info
        display: flex
        flex: 1 1 auto
        align-items: center
        color: $gray-text
        flex-wrap: wrap
        gap: 1rem

        @media screen and (max-width: 600px)
          flex-direction: column
          align-items: flex-start
          flex: 1 1 100%

        .blockinfo
          word-break: break-word
          flex: 0 1 auto

        .group
          display: flex
          flex: 0 0 auto
          align-items: center

          .triangleicon
            margin-right: .5rem
            width: 2.6rem
            height: 2.6rem

            .triangle
              height: 2.6rem

            .inner-icon
              height: 1.4rem
              transform: translate(-50%, -40%)

          .label
            margin-right: 1rem

          span,
          a
            display: inline-flex
            text-decoration: none
            min-height: 1.2em
            transition: color .3s ease

            &:not(.label)
              color: $black

            .icon
              flex: 0 0 auto

          a:hover
            color: var(--secondary-dark)

            .label
              color: $gray-text

    .owner-info
      display: flex
      align-items: center
      gap: 2rem
      padding: 1rem 2.5rem
      border-top: 1px solid $CONTRAST_PAPER_WHITE
      color: $DEEP_SPACE_BLACK

      .owner-title
        font-weight: 700
        font-size: 1.4rem

      .owner-name
        font-weight: 400
        font-size: 1.4rem

    .icon
      &.small
        margin: 0 .5rem
        vertical-align: text-bottom
        height: 1.6rem

      &.mobileonly
        display: none

        @media screen and (max-width: 800px)
          display: inline-flex

    .attached-products
      position: relative
      overflow: hidden
      border-top: 1px solid transparent

      .top-border
        position: absolute
        top: 0
        left: 0
        right: 0
        width: 100%
        height: 0.2rem
        stroke: var(--secondary-light)
        z-index: 1

      .product-list
        .product
          padding: 2.5rem
          box-sizing: border-box
          margin-bottom: 1rem

          &:last-child
            margin-bottom: 0

          &.deleted
            opacity: 0.5

          &.orange
            background-color: $primary-orange

            .midline
              &:before
                background-color: $secondary-orange-light

          &.blue
            background-color: $primary-blue

            .midline
              &:before
                background-color: $secondary-blue-light

          &.green
            background-color: $primary-green

            .midline
              &:before
                background-color: $secondary-green-light

          &.red
            background-color: $primary-red

            .midline
              &:before
                background-color: $secondary-red-light

          &.gray
            background-color: $gray-background

            .midline
              &:before
                background-color: $gray-border

          .productline
            display: flex
            align-items: stretch
            gap: 2rem

            @media screen and (max-width: 800px)
              flex-direction: column
              gap: 2rem

            .productline-info
              flex: 1 0
              display: flex
              flex-direction: column
              justify-content: center
              gap: 1rem

              .labels
                display: flex
                align-items: center
                gap: 1rem

              .productline-info_title
                width: 100%
                margin: 0

              .productline-info_info
                display: flex
                align-items: center
                flex-wrap: wrap
                gap: 1.5rem
                font-size: 1.4rem
                color: $black
                overflow: hidden

                & > span
                  flex: 0 0 auto

                .price
                  font-weight: 600
                  font-size: 1.5rem

                a
                  text-decoration: none
                  transition: color .3s ease
                  color: $black

                a:hover
                  color: var(--secondary-dark)

              .icon
                margin-left: 0

              .event-time
                margin-top: .5rem

            .productline-stage
              display: flex
              flex-direction: column
              align-items: flex-end
              justify-content: space-between
              gap: 1rem

              @media screen and (max-width: 1000px)
                align-items: start

              @media screen and (max-width: 450px)
                justify-content: stretch
                gap: 2rem

              .status-button-wrapper
                display: flex
                align-items: center

                @media screen and (max-width: 1000px)
                  max-width: none
                  flex-direction: row
                  justify-content: space-between
                  align-items: center

                @media screen and (max-width: 450px)
                  flex-direction: column
                  justify-content: stretch
                  align-items: start
                  gap: 2rem

                .margin-right
                  margin-right: 2.5rem

                  @media screen and (max-width: 450px)
                    margin-right: 1.5rem

              &.one-line
                flex-direction: row
                justify-content: flex-end
                align-items: center
                gap: 2rem

                @media screen and (max-width: 800px)
                  justify-content: space-between

                @media screen and (max-width: 450px)
                  flex-direction: column
                  justify-content: stretch
                  align-items: start

              .state-text
                display: inline-flex
                align-items: center
                line-height: 1.5
                color: $black
                font-size: 1.4rem
                font-weight: 600

                .inner-text
                  text-overflow: ellipsis
                  overflow: hidden
                  white-space: nowrap

                  @media screen and (max-width: 800px)
                    text-overflow: initial
                    max-width: none
                    white-space: normal

                .iconrectangle
                  display: inline-flex
                  height: 2.5rem
                  width: 2.5rem
                  margin-right: 1rem
                  align-items: center
                  justify-content: center
                  background-color: var(--secondary-light)

                  .icon
                    max-width: none
                    height: auto
                    width: 1.4rem

              .action-button
                @media screen and (max-width: 450px)
                  width: 100%

                &:hover
                  background-color: var(--secondary-dark)

              .assignment-accept-decline
                display: flex
                flex-direction: row
                align-items: center
                gap: 2rem

                @media screen and (max-width: 800px)
                  flex-direction: row-reverse
                  flex-wrap: wrap-reverse

                @media screen and (max-width: 600px)
                  flex-direction: row
                  flex-wrap: wrap-reverse

                .loading
                  width: 4rem !important
                  height: 4rem !important
                  margin-right: 2rem
                  display: inline-flex
                  justify-content: center
                  padding-top: 0

                .assignment-actions
                  display: flex
                  align-items: center
                  gap: 2rem

                  @media screen and (max-width: 600px)
                    flex-wrap: wrap
                    gap: 1rem

                  .accept,
                  .decline
                    flex: 0 0 auto

  .pipedrive-link
    text-decoration: underline
    color: $black

  .pipedrive-link:hover
    color: $secondary-orange-dark

  .reasons-popup
    width: 50rem

    @media screen and (max-width: 600px)
      width: auto

  .reasons-wrap
    margin: 2rem 0

    .reasons-header
      margin-bottom: 1rem

    input[type="text"]
      width: 100%

    label
      span
        font-weight: 600

    .reason
      display: flex
      flex-flow: column wrap
      align-items: flex-start

      .children-wrapper
        margin-left: 3rem
        margin-top: -0.5rem
        margin-bottom: 1rem

      .reason-info
        font-size: 1.2rem

        .important
          color: $secondary-red-dark

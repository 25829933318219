@import 'styles/variables'

.marketingSidebar
  max-width: 350px

  &.isMobile
    max-width: unset

  h4
    font-weight: 600

  .formField
    margin-bottom: 1.5rem

  .cta
    width: 100%

  .wrapBox
    .formField
      margin-bottom: 0

  .keywordInput
    min-height: 12rem

  .switch
    button.switchButton
      border-radius: 8px
      background-color: white
      width: 100%
      border: none
      color: black

      \:global(.textandicon-wrap)
        text-align: center
        justify-content: center
        gap: .5rem

      &.inactive
        background: transparent
        color: grey

      &:hover
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05)

        &:disabled
          box-shadow: none

  label:global(.checkbox)
    justify-content: flex-start !important

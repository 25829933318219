@import 'styles/variables'

@mixin section
  .section
    display: flex
    flex-flow: column wrap
    box-sizing: border-box
    flex-grow: 1
    justify-content: center
    align-items: stretch

    &.right
      justify-content: flex-start
      align-self: stretch
      max-width: 50%

    .orange
      color: $secondary-orange-dark
      font-size: 1.6rem
      font-weight: 500

    .plan
      color: $black
      font-size: 1.8rem

    .infoWrapper
      flex-grow: 0

@mixin infoWrapper
  .infoWrapper
    display: flex
    flex-flow: column wrap
    box-sizing: border-box
    flex-grow: 1
    justify-content: center
    align-items: center
    margin: 1.2rem

    &.spaceBetweenContentWrapper
      flex-direction: row
      justify-content: space-between
      flex-wrap: nowrap
      gap: 5rem
      margin: 1rem 0

      @media screen and (max-width: 800px)
        flex-direction: column
        align-items: flex-start
        gap: 2rem

      .button
        width: max-content

    .buttonWithTooltipWrapper
      display: inline-flex
      align-items: center
      cursor: help

      @media screen and (max-width: 800px)
        flex-direction: row-reverse

      .tooltipIcon
        margin-right: 1rem

        @media screen and (max-width: 800px)
          margin-left: 1rem

@mixin tableCell
  font-size: 1.4rem
  text-align: left
  vertical-align: middle
  padding: 1rem 0

.workspace

  .tabs
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr))
    justify-content: start
    gap: 1rem

  .heading
    font-size: 2.4em

  .content
    width: 100%

    .marginBottom
      margin-bottom: 1rem

    .midline
      display: flex
      align-items: center
      justify-content: space-between
      padding: 2rem 0

      &:before
        content: ''
        display: inline-block
        height: .1rem
        width: 6rem
        background-color: $secondary-orange-dark

    .boxWrapperSubscription
      display: flex
      flex-flow: column wrap
      border: .1rem solid $gray-border
      margin: 5rem 0

      .subscription
        display: flex
        flex-wrap: wrap
        box-sizing: border-box
        gap: 3rem
        justify-content: center
        align-items: center
        padding: 5rem

      &.smallPadding
        padding-top: 1rem
        padding-bottom: 1rem

      .subheading
        font-size: 2.4rem
        margin: 1.5rem 0

        &.noTopMargin
          margin-top: 0

        &.leftPadding
          padding-left: 1.5rem

      span, strong
        font-size: 1.4rem

      @include section

      .manageSubscription
        margin: 0 4rem 4rem 4rem
        display: flex
        flex-direction: column
        align-items: center

        .action
          width: auto

      @include infoWrapper

    .boxWrapper
      display: flex
      flex-wrap: wrap
      box-sizing: border-box
      gap: 3rem
      justify-content: center
      align-items: center
      padding: 5rem
      margin: 5rem 0
      border: .1rem solid $gray-border

      &.smallPadding
        padding-top: 1rem
        padding-bottom: 1rem

      .subheading
        font-size: 2.4rem
        margin: 1.5rem 0

        &.noTopMargin
          margin-top: 0

        &.leftPadding
          padding-left: 1.5rem

      span, strong
        font-size: 1.4rem

      @include section

      @include infoWrapper

      table
        width: 100%
        column-gap: 1rem
        border-spacing: 0

        th
          @include tableCell
          font-size: 1.6rem
          font-weight: 500
          text-align: center
          color: $secondary-orange-dark
          border-bottom: .1rem solid $gray-border
          padding: 5rem 0 1.5rem

          &.tableHeading
            border-bottom: none
            padding: 0

          &.orangeBorder
            border-bottom: .1rem solid $secondary-orange-dark

          &.blue
            color: $secondary-blue-dark
            padding-top: .5rem

        tbody

          .pendingList td
            padding: 0

          td
            @include tableCell
            border-bottom: .1rem solid $gray-border

            .responseStatus
              width: 70%
              font-size: 1.4rem
              margin: 2rem 0
              opacity: 1

            &.actionData
              display: flex
              align-items: center
              justify-content: flex-end

              .memberActionsContainer
                min-width: 5rem
                min-height: 4rem

              .innerMenuDropdown
                display: flex
                flex-flow: column nowrap
                min-width: 22rem
                text-align: left

                .removeWrap
                  margin: 0 1rem
                  padding-top: 2rem
                  border-top: .1rem solid $gray-border

                  .removeButton
                    min-width: 100%
                    text-align: center

                .rolesSection
                  display: flex
                  flex-flow: column

                  border-bottom: unset !important
                  padding: unset !important
                  margin: unset !important

                  .roleButton
                    text-align: left
                    padding-left: 1rem
                    height: 5rem

                    &:hover
                      background-color: $gray-background

                  .icon
                    width: 2.5rem

                  .selected
                    display: flex
                    align-items: center

                    .selectedIcon
                      font-size: 2rem

          .member
            display: flex
            flex-direction: column

@import 'styles/redesign-variables.sass'

.unavailabilities
  width: 100%
  max-width: 60rem
  min-width: 32rem

  @media screen and (max-width: 600px)
    min-width: inherit

  button
    font-weight: 600
    color: $gray-900 !important
    border-radius: unset !important
    text-decoration: underline

  .headerWithButton
    display: flex
    align-items: flex-end
    justify-content: space-between

    button
      margin-right: -1rem
      margin-bottom: 0.6rem
      font-size: 1.4rem !important

  .header
    margin-top: 0
    font-size: 1.8rem !important

  .blankContainer
    height: 250px
    border-radius: 0.8rem
    background: $beige-300

    display: flex
    flex-flow: column wrap
    align-items: center
    justify-content: center

    @media screen and (max-width: 600px)
      width: 100%
      height: 200px

    p
      margin: 0.5rem 0
      font-weight: 600
      font-size: 1.6rem
      text-align: center
      color: $gray-900

  .itemsContainer
    gap: 1rem
    display: flex
    flex-flow: row wrap

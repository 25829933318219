@import 'styles/variables.sass'

.fieldGrid

  p
    &.textInstruction
      margin: 0

      &.paddingBottom
        padding-bottom: 2rem

      .line
        display: block

        & + .line
          margin-top: 1rem

  .prefillCheckbox
    margin-left: 1rem
    color: $black
    font-size: 1.2rem
    font-weight: 700

  .phoneInput
    \:global(.error-message)
      display: block
      width: 100%

  \:global(.iti)
    display: block

    & + span:global(.error-message)
      display: block

  \:global(.address-map)
    & + .selectedAddress
      display: block
      margin: 1rem 0
      font-size: 1.2rem
      font-weight: normal
      color: $black

      strong
        font-size: 1.2rem

      .formattedAddress
        color: $gray-text

  input
    display: block
    width: 100%

@import 'styles/variables'
@import 'styles/theme-variables.sass'

.backdrop
  background: adjust-color($DEEP_SPACE_BLACK, $alpha: -0.5)
  position: fixed
  z-index: 101
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  transition: opacity 200ms ease

  &.popupEnter
    opacity: 0

    .content
      transform: translate(-50%, -50%) scale(0.6)

  &.popupEnterActive
    opacity: 1

    .content
      transform: translate(-50%, -50%) scale(1.05)

  &.popupExit
    opacity: 1

  &.popupExitActive
    opacity: 0

  .content
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) scale(1)
    background: $white
    min-width: 20rem
    max-width: 60rem
    padding: 2rem 2rem 1rem 2rem
    border-radius: 1rem
    border-color: $CONTRAST_PAPER_WHITE
    transition: transform 150ms ease
    box-shadow: 0 0 1rem .2rem adjust-color($DEEP_SPACE_BLACK, $alpha: -.7)

    @media screen and (max-width: 400px)
      min-width: 0
      width: 90vw

    .title
      font-size: 2rem
      margin-bottom: 2rem
      color: $black
      font-weight: bold

    .body
      font-size: 1.5rem
      margin-bottom: 2rem

      a
        text-decoration: none

    .actions
      border-top: 1px solid $gray-border
      padding-top: 1rem
      display: flex
      flex-flow: row
      gap: 2rem
      justify-content: flex-end
      align-items: center

      &.hiddenSecondaryButtonActions
        justify-content: flex-end

      .button
        flex: 0 0 auto

    .disabledMessage
      text-align: right
      margin-top: .5rem

@import 'styles/redesign-variables'
@import 'styles/variables'

.signupCard
  width: 38rem
  position: relative

  .cardWrap
    min-height: 58rem
    box-sizing: border-box
    border-radius: .8rem
    background-color: $white
    padding: 4.8rem 2rem
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center

    .cardContent
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      gap: 3.2rem

      .title
        color: $gray-900
        font-size: 2.4rem
        font-weight: 400
        font-family: $ff-sangbleu

      .description
        color: $gray-900
        font-size: 1.4rem
        text-align: center
        line-height: 2.5rem

  .note
    position: absolute
    padding: 2rem
    color: $gray-700
    font-size: 1.4rem
    font-style: italic
    text-align: center

@import 'styles/variables'
@import 'styles/redesign-variables'

.modal
  width: 90%
  max-width: 70rem

  input.calendar
    border: none !important
    box-shadow: none !important

  .children
    padding-left: 3rem
    margin-bottom: 2rem

    .warning
      color: $coral-600

    .error
      color: $coral-600
      position: absolute
      bottom: -1.5rem
      left: 0

@import 'styles/redesign-variables'

.orderCancellationPopup

  .orderCancellationPopupModalContent
    position: relative
    width: 60rem
    min-height: 20rem
    padding: 2rem 2.4rem 0 2.4rem
    box-sizing: border-box

  .termsLink
    text-decoration: none
    color: $gray-700

@import 'styles/redesign-variables'

.expandedVisualPopup
  padding: unset
  width: 80vw
  height: calc(100% - 13.6rem)

  .error
    color: $gray-900
    font-size: 1.4rem
    font-weight: 600
    text-align: center

@import 'styles/redesign-variables'

.header
  margin-bottom: 2rem

  h3
    margin: 0
    font-size: 1.8rem
    user-select: none

  p
    font-size: 1.4rem

.infoContainer
  position: relative
  padding: 2rem
  width: 100%
  min-height: 30rem

  h4
    margin: 0
    font-weight: 500
    font-size: 1.6rem !important

  pre
    margin: 0
    line-height: 1.5
    font-size: 1.4rem
    white-space: pre-wrap

  textarea
    border-radius: 0.8rem

    &:focus
      border-color: $blue-600 !important

@import 'styles/redesign-variables'
@import 'styles/variables'

.pricingPage
  padding: 5.6rem 0
  background-color: $beige-300
  min-height: calc(100% - 6.8rem)

  &.mobile
    padding: 4rem 2rem

  .pricingContent
    max-width: 112rem
    margin: auto

    &.mobile
      width: 100%

    .header
      display: flex
      flex-direction: column
      align-items: center
      gap: 1.2rem
      width: 100%
      margin: auto

      .title
        color: $gray-900
        font-size: 4rem
        font-weight: 400
        font-family: $ff-sangbleu

      .subTitle
        color: $gray-700
        font-size: 1.8rem
        font-weight: 400
        text-align: center

    .planPeriod
      display: flex
      justify-content: center
      align-items: center
      margin: 3.2rem 0 4rem
      font-size: 1.4rem
      font-weight: 500
      color: $gray-700

      .active
        font-weight: 600
        color: $gray-900

      .discountLabel
        font-size: 1.2rem
        font-weight: 400
        margin-left: .4rem
        padding-top: 0.2rem

      .planPeriodSwitch
        margin: 0 1.2rem

    .plans
      width: 100%
      display: flex
      gap: 2.4rem
      justify-content: center

      &.mobile
        flex-direction: column
        align-items: center
        font-size: 1.8rem
        font-weight: 400

  .reviewCardWrap
    display: flex
    justify-content: space-between
    align-items: flex-start
    gap: 2.4rem
    margin-top: 8rem

    &.mobile
      flex-direction: column
      align-items: center

@import 'styles/variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .dashboard-content

    &.redesign-dashboard
      margin-top: 1.6rem !important
      padding: 0 4rem
      max-width: 90.4rem !important
      grid-column: 2

      @media screen and (max-width: 800px)
        padding: 0 2rem

      .order-cards-title
        font-size: 3rem
        font-weight: 700
        color: $gray-900
        padding: 1.6rem 0

        @media screen and (max-width: 800px)
          font-size: 2.4rem
          padding: 0.4rem 0

    .center
      text-align: center

    .tabs
      display: grid
      grid-gap: 2rem
      grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr))

    .title
      font-size: 1.6rem
      margin: 6rem 0 2rem

    .search-wrap
      display: grid
      grid-gap: 2rem
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr))
      margin: 2rem 0 0

      .search-box
        width: 100%
        display: flex
        flex-direction: column

        input
          width: 100%

  .dashboard-filters
    .filter-section-label
      color: $gray-700
      font-weight: 600
      font-size: 1.4rem
      height: 2rem
      display: flex
      align-items: center

    .ownership-tabs
      display: flex
      justify-content: space-between
      padding: .4rem
      background: $beige-500
      border-radius: 8px

    .order-status-filter-tabs
      display: flex
      flex-direction: column
      gap: .8rem

    .member-filter-wrap
      margin-top: 2.4rem

      .member-filter-header
        min-height: 1.8rem
        display: flex
        justify-content: space-between
        align-items: baseline

@import 'styles/redesign-variables'

.header
  width: 100vw
  z-index: 100
  position: absolute
  min-height: 6.8rem
  background: $white
  box-shadow: $box-shadow-bottom

  top: 0
  left: 0
  right: 0

  .headerContent
    display: flex
    align-items: center
    font-weight: 600
    line-height: 150%
    padding: 1.6rem 2.4rem

    .titleContainer
      display: flex
      align-items: baseline

      .title
        color: $gray-900
        font-size: 2rem

      .subtitle
        font-size: 1.6rem
        color: $gray-700

    .extraContent
      margin: 0 auto
      max-width: 25rem

    .closeButton
      color: $gray-800
      outline: 1px solid $gray-200

@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .previewContainer
    width: fit-content
    min-width: 19.2rem
    max-height: 8rem
    background: white
    box-sizing: border-box
    border-radius: .8rem
    border: .1rem solid $blue-600
    margin-bottom: 1.2rem
    display: flex
    align-items: center
    justify-content: center
    padding: 1.6rem
    position: relative

    .dropdown
      position: absolute
      top: 0
      right: 0
      height: .5rem

      .innerDropdown
        min-width: fit-content
        border-radius: .8rem

      .removeButton
        color: $coral-600
        padding: .4rem .8rem
        margin: 0

      .dropdownButton
        display: none

        &.optionsActive
          display: flex
          align-items: center
          padding: 0 .1rem 0 0
          border: 0
          height: 1.2rem

    .previewBorder
      min-width: 100%
      min-height: 100%
      border-radius: .5rem
      border: .1rem solid $gray-400
      display: flex
      justify-content: center
      background-image: url('../../../../../assets/img/bg/checker_background.svg')

      .previewImage
        max-height: 4.8rem
        background: transparent

@import 'styles/redesign-variables.sass'

.ownerFilterTab
  box-sizing: border-box
  display: flex
  justify-content: center
  align-items: center
  height: 3.6rem
  cursor: pointer
  padding: .8rem
  border-radius: .6rem
  transition: background-color .3s ease
  color: $gray-700
  font-size: 1.4rem
  font-weight: 600
  flex-grow: 1
  min-width: 40%

  &:hover
    color: $gray-900

  &.active
    background: $white
    color: $gray-900
    box-shadow: $box-shadow-bottom

  &.unavailable
    cursor: help

  .contentWrapper
    display: flex
    gap: .4rem
    align-items: center

    .tabIcon
      display: flex
      align-items: center
      padding-top: 0.2rem
      height: 2rem
      max-width: fit-content
      font-size: 1.6rem

@import 'styles/variables'

.previewGallery
  position: fixed
  height: calc(100vh - 6.8rem - 6.8rem) // deduct header and footer
  overflow-y: auto
  top: 7rem
  padding: 3rem 0 4rem 0

  .errorContainer
    display: flex
    height: 100%
    width: 24.8rem

    .errorMessage
      color: $secondary-red-dark
      text-align: center
      margin: auto

@import 'styles/redesign-variables'

.galleryFeedbackWrapper
  gap: 2rem
  padding: 2rem
  display: flex
  flex-flow: column wrap
  align-items: flex-start
  background-color: $coral-100
  border: .1rem solid $coral-100

  h3
    margin: 0
    color: $gray-900 !important

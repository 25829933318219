@import 'styles/variables'

html[bkbn-style="old"]

  .galleryimage
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    position: relative
    width: 30rem
    height: 20rem
    background-color: $gray-background
    background-position: center center
    background-size: contain
    background-repeat: no-repeat
    box-sizing: border-box
    color: $black

    .hover
      .noimageinfo
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &.withimage
      border: 1px solid $gray-border
      background-color: $white

      .hover
        display: block
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        width: 100%
        height: 100%
        box-sizing: border-box
        border: 1px solid transparent
        transition: background-color .15s ease, border .15s ease, opacity .15s ease

      &.selectable
        .hover
          &:hover
            cursor: pointer
            background-color: adjust-color($black, $alpha: -0.9)

      &.selected
        .hover
          border: .5rem solid $secondary-orange-light

        .label
          background-color: $secondary-orange-light

      &.purchased
        .hover
          border: .5rem solid $secondary-green-light

        .label
          background-color: $secondary-green-light

      &.selected.purchased
        .hover
          border-top-color: $secondary-green-light
          border-left-color: $secondary-green-light
          border-bottom-color: $secondary-orange-light
          border-right-color: $secondary-orange-light

        .label
          background-color: $secondary-green-light

      &.faded
        &::before
          content: ''
          position: absolute
          top: 0
          right: 0
          bottom: 0
          left: 0
          width: 100%
          height: 100%
          box-sizing: border-box
          background-color: adjust-color($white, $alpha: -0.2)

      &.wait
        cursor: wait

        .check
          cursor: wait

    &.droppable
      .hover
        outline: 0

      &.dragging
        .hover
          background-color: $primary-blue
          opacity: 0.75

      &.dragging-over
        .hover
          background-color: $secondary-blue-light
          opacity: 0.9

    .text
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      text-align: center
      width: 100%
      height: 100%
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      box-sizing: border-box

    .label
      display: flex
      justify-content: center
      align-items: flex-start
      position: absolute
      top: 0
      left: 0
      min-width: 2.5rem
      max-width: 100%
      min-height: 2.5rem
      max-height: 5rem
      padding: .5rem
      color: $black
      word-break: break-all
      overflow: hidden
      box-sizing: border-box
      background-color: $gray-border

    .check

      &.select
        top: 2rem
        right: 2rem
        position: absolute

      &.delete
        svg
          fill: $secondary-red-dark

      &.replace
        top: auto
        right: auto
        bottom: 2rem
        left: 6rem

        svg
          fill: $secondary-blue-dark

@import 'styles/redesign-variables'

.userFilter
  padding: 2rem 0
  position: relative

  .usersWrap
    display: flex
    box-sizing: border-box
    flex-direction: column

    .label
      width: 100%

    :last-child
      .label
        margin-bottom: 0

  .labelAfter
    width: 100%
    display: flex !important
    justify-content: space-between
    flex-shrink: 5
    color: $black

    .count
      align-content: flex-end

  .expandButtonWrap
    text-align: center
    margin-top: 2.4rem

@import 'styles/redesign-variables.sass'

.workingHours
  width: 100%
  max-width: 32rem

  @media screen and (max-width: 600px)
    max-width: 100%

  .header
    margin-top: 0
    font-size: 1.8rem !important

  .headerWithButton
    display: flex
    align-items: center
    justify-content: space-between

  button
    font-weight: 600
    align-self: flex-end
    color: $gray-900 !important
    border-radius: unset !important
    text-decoration: underline

    margin-right: -1rem
    margin-bottom: 0.6rem
    font-size: 1.4rem !important

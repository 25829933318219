@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-bold-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bold-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bold-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bold-webfont.svg#hk_groteskbold') format("svg")
  font-weight: 700
  font-style: normal
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.svg#hk_groteskbold_italic') format("svg")
  font-weight: 700
  font-style: italic
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-italic-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-italic-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-italic-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-italic-webfont.svg#hk_groteskitalic') format("svg")
  font-weight: 400
  font-style: italic
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-light-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-light-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-light-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-light-webfont.svg#hk_grotesklight') format("svg")
  font-weight: 300
  font-style: normal
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.svg#hk_grotesklight_italic') format("svg")
  font-weight: 300
  font-style: italic
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-medium-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-medium-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-medium-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-medium-webfont.svg#hk_groteskmedium') format("svg")
  font-weight: 500
  font-style: normal
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.svg#hk_groteskmedium_italic') format("svg")
  font-weight: 500
  font-style: italic
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-regular-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-regular-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-regular-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-regular-webfont.svg#hk_groteskregular') format("svg")
  font-weight: 400
  font-style: normal
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-semibold-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibold-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibold-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibold-webfont.svg#hk_grotesksemibold') format("svg")
  font-weight: 600
  font-style: normal
  font-display: swap

@font-face
  font-family: 'HK Grotesk'
  src: url('../../assets/fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.woff2') format("woff2"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.woff') format("woff"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.ttf') format("truetype"), url('../../assets/fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.svg#hk_grotesksemibold_italic') format("svg")
  font-weight: 600
  font-style: italic
  font-display: swap

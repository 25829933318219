@import 'styles/variables'
@import 'styles/theme-variables.sass'

$dark-colors: ('gray': $DEEP_SPACE_BLACK, 'blue': $MEDITERRANEAN_BLUE, 'orange': $DARK_ORANGE, 'red': $NO_RED, 'green': $YES_GREEN)
$colors: 'gray', 'blue', 'orange', 'red', 'green'

.tag
  position: relative
  display: inline-block
  box-sizing: border-box
  font-family: $ff-sailec
  overflow: hidden
  padding: .5rem 1rem
  font-weight: 600
  white-space: nowrap
  border-radius: $card-border-radius
  color: $white

  &.small
    font-size: 1.2rem
    height: 3rem
    line-height: normal

    svg
      font-size: 1.5rem

  &.large
    font-size: 1.4rem
    height: 3.4rem
    line-height: 1

    svg
      font-size: 1.8rem

  @each $color in $colors
    &.#{$color}
      background-color: map-get($dark-colors, #{$color})
      border: .1rem solid map-get($dark-colors, #{$color})

  svg
    fill: $white !important

.textAndIconWrap
  display: flex
  align-items: center
  justify-content: center
  height: 100%

  .icon
    display: flex
    margin-right: .5rem

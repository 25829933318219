@import 'styles/variables'

.boxContent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start

  // ID to overwrite imposed margin
  #tosCheck
    margin-bottom: 2.5rem
    display: flex
    align-items: center
    gap: 1rem

    .link
      color: $secondary-orange-dark
      font-weight: bolder

  .billingPeriods
    margin-bottom: 4rem
    text-align: center

    .billingPeriodsRow
      display: flex
      flex-flow: row wrap
      justify-content: center
      align-items: stretch
      gap: 2rem

      .billingPeriod
        display: block
        flex: 0 0 auto
        padding: 4rem
        background: $gray-border
        border: 2px solid $gray-border
        border-radius: 8px
        color: $black
        font-size: 1.5rem
        font-weight: bold
        cursor: pointer
        transition: border 100ms ease-in

        &.isSelected
          cursor: default
          border: 2px solid $secondary-orange-dark

  .failedEmailsWrap
    text-align: left
    display: flex
    width: 100%
    max-width: 60rem
    flex-direction: column
    justify-content: flex-start
    align-items: center

    p
      line-height: normal
      font-size: 1.4rem
      margin-bottom: 2rem

    .failedEmails
      display: flex
      flex-flow: row wrap
      justify-content: center
      align-items: flex-start
      gap: 2rem
      margin-bottom: 4rem

      .failedEmail
        flex: 0 0 auto
        padding: 1rem
        border-radius: 4px
        background: $gray-border
        font-weight: bold
        font-size: 1.4rem

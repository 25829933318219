// Redesign new styles
// Figma style guide: https://www.figma.com/file/InAsxoYf47YuQ8HfkvUt80/Style-Guide?type=design&node-id=115%3A674&mode=design&t=1jMvwNvHKoQJKHAA-1

// Box shadows
$box-shadow: 0 .4rem 1.6rem rgba(19, 24, 29, 0.06)
$box-shadow-bottom: 0 .2rem .2rem rgba(19, 24, 29, 0.04)
// Box shadows on light
$box-shadow-xs: 0px 1px 4px 0px rgba(19, 24, 29, 0.06)
$box-shadow-sm: 0px 1px 4px 0px rgba(19, 24, 29, 0.10)
$box-shadow-md: 0px 2px 8px 0px rgba(19, 24, 29, 0.10)
$box-shadow-lg: 0px 4px 16px 0px rgba(19, 24, 29, 0.10)
$box-shadow-xl: 0px 8px 24px 0px rgba(19, 24, 29, 0.10)
$box-shadow-2xl: 0px 8px 24px 0px rgba(19, 24, 29, 0.10)
$box-shadow-3xl: 0px 24px 48px 0px rgba(19, 24, 29, 0.10)
$box-shadow-3xl: 0px 32px 64px 0px rgba(19, 24, 29, 0.10)

// COLORS 
// Our design system leverages a purposeful set of color styles as the perfect starting point for any brand or project. 
// When it comes to color, contrast is critical for ensuring text is legible. We've added WCAG 2.1 contrast ratios 
// to our color system so you can make sure you're designing with accessibility in mind.

// BRAND COLORS 
// These are the main colors that make up the majority of the colors used in the design system.

// Gray scale
// Gray is a neutral color and is the foundation of the color system. 
// Almost everything in UI design / text, form fields / are usually gray.
$gray-100: #FEFEFE
$gray-200: #FBFBFB
$gray-300: #EFF0F0
$gray-400: #DCDDDE
$gray-500: #C1C2C3
$gray-600: #9D9FA1
$gray-700: #727578
$gray-800: #404448
$gray-900: #252A2E
$gray-1000: #13181D
$gray-1100: #07080A

// Coral -  for error or emergencies
$coral-100: #FCF0ED
$coral-200: #FAE4DE
$coral-300: #F4CABD
$coral-400: #F1A08A
$coral-500: #E88668
$coral-600: #E26D49
$coral-700: #CE6342
$coral-800: #A54F35
$coral-900: #7B3C28
$coral-1000: #52281B
$coral-1100: #29140D

// Blue
$blue-100: #EDF7F8
$blue-200: #DEF0F3
$blue-300: #BEE0E6
$blue-400: #A7DBE2
$blue-500: #7DC1CD
$blue-600: #4CAABB
$blue-700: #459BAA
$blue-800: #377C88
$blue-900: #295D66
$blue-1000: #1C3E44
$blue-1100: #0E1F22

// Beige
$beige-100: #FEFEFD
$beige-200: #FDFDFB
$beige-300: #FBFBF7
$beige-400: #F8F8F4
$beige-500: #F6F6F0
$beige-600: #F3F3EA
$beige-700: #DDDDD5
$beige-800: #B1B1AA
$beige-900: #858580
$beige-1000: #585855
$beige-1100: #2C2C2B

// SECONDARY COLORS
// Along with primary colors, it's helpful to have a selection of secondary colors 
// to use in components such as pills, alerts and labels. These secondary colors 
// should be used sparingly or as accents, while the primary color(s) should take precendence.

// Neutral
$white: #FFFFFF
// True black is usually not much used sinci it's too harsh in many cases
$black: #000000
// Slightly off black to use for more mild contrast
$off-black: #22292E

// Green - for validation / good
$green-100: #E6FBF2
$green-200: #D1F7E8
$green-300: #A2EFD1
$green-400: #74E7BA
$green-500: #46DFA3
$green-600: #23CC89
$green-700: #1CA56F
$green-800: #178459
$green-900: #116343
$green-1000: #0B422C
$green-1100: #062116

// Orange - for small warnings and ongoing things
$orange-100: #FEF3E7
$orange-200: #FDE9D3
$orange-300: #FAD3A7
$orange-400: #F8BC7B
$orange-500: #F6A64F
$orange-600: #F39023
$orange-700: #DC790C
$orange-800: #B06109
$orange-900: #844907
$orange-1000: #583005
$orange-1100: #2C1802

@import 'styles/variables'

html[bkbn-style="old"]

  .deal-detail-popup
    .deal-detail-popup-modal-content
      position: relative
      width: 80rem
      min-height: 20rem
      padding: 4rem
      box-sizing: border-box

      .inside
        display: flex
        flex-direction: column
        height: 100%
        box-sizing: border-box

        .heading
          margin-bottom: 2rem
          display: flex
          // justify-content: space-between
          align-items: baseline

          h3.title
            font-size: 2.2rem
            color: $black
            font-weight: normal
            margin: 0

          .number
            display: inline-block
            color: $black
            font-size: 1.4rem
            padding: .5rem 1rem
            background-color: $secondary-blue-light
            margin-left: 2rem

        .reference
          margin: 0

        .line
          display: flex
          flex-wrap: wrap
          align-items: center
          font-size: 1.4rem
          margin-bottom: 1rem

          @media screen and (max-width: 800px)
            flex-direction: column
            align-items: flex-start
            margin-bottom: 2rem

          .triangleicon
            margin-right: .5rem
            margin-left: 0
            width: 2.6rem
            height: 2.6rem

            .triangle
              height: 2.6rem

            .inner-icon
              height: 1.6rem
              transform: translate(-50%, -40%)

          span, a
            display: inline-block
            margin-left: 1rem

            @media screen and (max-width: 800px)
              margin-left: 0
              margin-bottom: 1rem

          a
            color: $gray-text
            text-decoration: none

          .block
            display: inline-block
            background-color: $primary-orange
            padding: .2rem .5rem
            display: flex
            align-items: center

            .icon
              margin-right: 1rem
              height: .9em
              vertical-align: unset

            span
              margin-left: 0

              @media screen and (max-width: 800px)
                margin-bottom: 0

          a.block
            transition: background .15s ease

            &:hover
              background-color: $secondary-orange-light

        .margin-bottom
          margin-bottom: 2rem

        .date-button, .reference-button
          height: auto
          padding: .4rem 1rem

        .margin-left
          margin-left: 2rem

          @media screen and (max-width: 800px)
            margin-left: 0

        .edit-box
          display: flex
          flex-direction: column
          padding: 1rem 0

          @media screen and (max-width: 800px)
            margin-bottom: 2rem
            padding: 0

          .input-row
            display: flex
            flex-wrap: wrap
            align-items: flex-end

            @media screen and (max-width: 800px)
              flex-direction: column
              align-items: flex-start

            .input
              margin-right: 2rem

              @media screen and (max-width: 800px)
                margin-bottom: 1.5rem

              label
                display: block

            .reference-group
              flex-grow: 1

              @media screen and (max-width: 800px)
                width: 100%

              .reference
                width: 100%

          .message-wrapper
            margin-top: 1rem

        .midline
          display: flex
          align-items: center
          justify-content: space-between
          padding: 1.5rem 0 2.5rem

          @media screen and (max-width: 800px)
            padding: 0.5rem 0 1.5rem

          &:before
            content: ''
            display: inline-block
            height: .1rem
            width: 2.4rem
            background-color: $gray-border

        .blockinfo
          margin-bottom: 1rem

          @media screen and (max-width: 800px)
            margin-bottom: 2rem

        *:last-child
          margin-bottom: 0

      .space-bottom
        margin-bottom: 2rem

      .cancel-wrap
        margin-top: 2rem

        .cancel-error
          padding: 2rem
          background: $secondary-red-light
          width: 100%

          h4.error-title
            margin: 0 0 1rem 0
            color: $secondary-red-dark

          .error-message
            margin: 0
            font-size: 1.4rem
            color: $secondary-red-dark

        .button-with-text
          display: flex
          flex-flow: row wrap
          justify-content: flex-start
          align-items: center
          gap: 1rem

          .spinner
            height: 4rem !important

            svg
              height: 4rem

          .success-block
            width: 100%

          .terms
            flex: 1 1 20rem
            font-weight: bold
            color: $black

            a
              color: $black
              transition: color .15s ease

              &:hover
                color: $secondary-orange-dark

  .reasons-wrap
    margin: 2rem 0

    .reasons-header
      margin-bottom: 1rem

    input[type="text"]
      width: 100%

@import 'styles/variables'

.stagingList
  display: flex
  flex-flow: column nowrap
  gap: 2rem

  .actionBar
    position: absolute
    bottom: 0

  .stagingItem
    display: flex
    flex-flow: row wrap
    justify-content: space-between
    align-items: center
    border: 1px solid $gray-light-text
    border-radius: .8rem
    padding: 2rem
    gap: 2rem

    &.isCompleted
      border-color: $green-stage

    .checkIcon
      font-size: 3rem
      color: $green-stage

    .stagingTitle
      color: $gray-dark-text

      span
        display: block
        margin-bottom: .75rem
        text-transform: uppercase
        letter-spacing: 1px
        font-size: 1.2rem

      h4
        margin: 0
        font-size: 1.8rem
        font-weight: 500

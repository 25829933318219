@import 'styles/variables'

.requestStatus
  display: block
  width: 100%
  position: relative
  border-radius: 6px
  overflow: hidden
  background-color: transparent
  transition-property: height, background-color, margin
  transition-duration: 200ms, 200ms, 100ms
  transition-timing-function: ease-in
  transition-delay: 0ms, 0ms, 0ms
  text-align: left

  .content
    position: relative
    padding: 1.5rem
    padding-top: 1.4rem

    .main
      position: relative
      display: flex
      flex-flow: row
      align-items: center
      gap: 1rem

      span
        color: $white

      p
        text-align: left
        font-size: 1.45rem
        font-weight: 500
        margin: 0 !important // override potentional global styles
        color: $white
        flex: 1 1 25rem
        line-height: 1

        &.heading
          padding-bottom: .25rem

        .gray
          color: $gray-text

      .icon
        flex: 0 0 auto
        font-size: 2.5rem

      .loading
        width: 2rem !important
        height: 2rem !important

      .close
        transform: translate(30%, -30%)

    .meta
      flex: 0 0 100%
      font-weight: 500
      margin-top: 1rem
      color: $white

      *
        color: $white

      .errorMessage
        font-style: italic

  &.isClosed
    // for smooth close margin animation (yes, i've visually chose the correct amount)
    transition-delay: 0ms, 0ms, 140ms

  &.success
    background-color: $secondary-green-dark

  &.error
    background-color: $secondary-red-dark

  &.pending
    // Opaque color for coforming to different backgrounds
    background-color: rgba(0, 0, 0, 0.075)

    .main.isCenter
      justify-content: center

@import 'styles/variables'

html[bkbn-style="old"]

  .downloadWrap
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 3rem
    max-width: 24rem
    text-align: center
    display: flex
    flex: 2 0

    @media screen and (max-width: 1024px)
      flex-direction: row
      max-width: unset

    @media screen and (max-width: 640px)
      flex-direction: column
      gap: 1rem

    .buttonSection
      margin-top: 2rem
      text-decoration: none

    p:last-child
      margin-bottom: 0

    .downloadTitle
      margin: 0 2rem 2rem 2rem

    .downloadText
      display: flex
      flex-direction: column
      width: 100%
      padding: 5rem 2rem

      @media screen and (max-width: 640px)
        padding: 2rem

      .link
        text-decoration: underline

      p
        margin-bottom: 1rem

      .spinner
        max-height: 3.2rem
        margin: 0 auto
        transform: translateY(-1.6rem)

      & + .button
        margin-top: 2rem

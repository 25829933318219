@import 'styles/redesign-variables'

.languagePopup
  position: relative
  width: 47.2rem
  padding: 2.4rem
  color: $gray-900
  font-size: 2rem
  font-weight: 600

  .container
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: 1.6rem

    .languageButton
      box-sizing: border-box
      background-color: transparent
      border-radius: 4px
      border: 1px solid $gray-200
      font-size: 1.4rem
      height: 5.4rem
      width: 100%
      padding: .8rem 1.9rem
      cursor: pointer
      transition: background-color .15s ease, box-shadow .15s ease
      position: relative
      overflow: hidden
      justify-self: center

      .lanaguage
        color: $gray-900
        font-weight: 600

      &.selected, &:hover, &:focus
        background-color: $blue-100
        border: 1px solid $blue-600

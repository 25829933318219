@import 'styles/redesign-variables'

.contactUsPopup
  position: relative
  width: 47.2rem
  padding: 2.4rem
  color: $gray-900
  font-size: 2rem
  font-weight: 600

  .container
    display: flex
    flex-direction: column

    .infoContainer
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-bottom: 1.2rem

      .supportHours
        font-size: 1.6rem
        margin-bottom: 0.5rem

      .officeHours
        font-weight: 500
        font-size: 1.6rem
        margin-bottom: 0.9rem

      .chatbotInfo
        font-weight: 400
        font-size: 1.4rem

    .phone
      margin-top: .9rem
      text-decoration: none
      font-size: 1.4rem
      font-weight: 400
      color: $gray-900

      .countryFlag
        height: 1.5rem
        width: 2.1rem
        margin: 0 1.2rem 0 0
        vertical-align: bottom
        border-radius: .2rem

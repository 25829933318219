h4.upsellTitle
  margin: 0
  margin-bottom: 1rem
  font-size: 1.6rem

.upsellInfo
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: space-between
  gap: 2rem

  p
    margin: 0
    flex: 1 1 auto
    line-height: normal

  .upsellAction
    flex: 0 0 auto

@import 'styles/variables'

$light-colors: ('blue': $blue-stage-light, 'orange': $orange-stage-light, 'green': $green-stage-light, 'red': $red-stage-light)
$dark-colors: ('blue': $blue-stage, 'orange': $orange-stage, 'green': $green-stage, 'red': $red-stage)
$colors: 'blue', 'green', 'orange', 'red'

@each $color in $colors
  .#{$color}Border
    border: 1px solid map-get($dark-colors, #{$color}) !important

  .#{$color}Background
    color: $white !important
    background-color: map-get($dark-colors, #{$color})
    border: 1px solid map-get($dark-colors, #{$color}) !important

  .#{$color}
    padding: 2rem
    color: $black
    background: map-get($light-colors, #{$color})

    .verticalDivider
      border: 0
      width: 1px
      height: 80px
      margin-left: 1.5rem
      background-color: map-get($dark-colors, #{$color})

      @media screen and (max-width: 1024px)
        display: none

    button
      min-height: 3rem
      height: 100% !important

      &.primary
        color: $white !important
        background: map-get($dark-colors, #{$color}) !important
        border-color: map-get($dark-colors, #{$color}) !important

      &.secondary
        background: transparent !important
        color: map-get($dark-colors, #{$color}) !important
        border-color: map-get($dark-colors, #{$color}) !important

      &.destructive
        color: map-get($dark-colors, 'red') !important
        border-color: map-get($dark-colors, 'red') !important

      &:hover
        box-shadow: none !important

      &:disabled
        color: rgba(0, 0, 0, 0.3) !important
        border-color: rgba(0, 0, 0, 0.2) !important
        background-color: transparent !important

.orderAutomationWrapper
  display: grid
  margin-top: 2rem
  min-height: 100px
  column-gap: 1.5rem
  align-items: center
  grid-template-columns: 1fr 2fr 1fr

  @media screen and (max-width: 800px)
    gap: 1.5rem
    grid-template-columns: 1fr

  .requestStatusGrid
    grid-column-end: -1
    grid-column-start: 1

  .statusDetails
    display: flex
    align-items: center
    justify-content: space-between

    span
      font-size: 1.2rem

    h3
      margin: 0.5rem 0

  .assignmentsWrapper
    gap: 1rem
    height: 100%
    display: flex
    flex-wrap: wrap
    align-items: center

    .contentWrapper
      gap: 1rem
      display: flex
      padding: 0.5rem
      min-width: 220px
      font-weight: 500
      border-radius: 0.4rem
      flex-direction: column

      .header
        font-size: 1.4rem

      .grid
        gap: 0.5rem
        display: flex
        flex-wrap: wrap

        .idTag
          font-size: 1.4rem
          width: fit-content
          border-radius: 1rem
          padding: 0.1rem 0.5rem

          &:before
            content: '#'

  .buttonsWrapper
    gap: 1rem
    display: flex
    margin-left: auto

    @media screen and (max-width: 800px)
      margin-left: 0

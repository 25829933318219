@import 'styles/variables'

.box
  position: relative
  width: 100%
  border: .1rem solid $gray-border
  box-sizing: border-box
  padding: 2rem

  .info
    p
      margin-top: 0

  .inputRow
    margin-top: 1.5rem
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    gap: 2rem

    .input

      & > label
        display: block

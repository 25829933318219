@import 'styles/variables'

html[bkbn-style="old"]

  .page.profile
    .page-content
      .top-row
        display: flex
        align-items: center
        margin-bottom: 5rem

        .backbutton
          margin-right: 4rem

        h1
          margin: 0
          font-size: 2em

      .white-content
        background: $white
        padding: 5rem 8rem

        @media screen and (max-width: 800px)
          padding: 5rem 4rem

        h2
          margin: 4rem 0 2rem

          &:first-child
            margin-top: 0

        .icon-section
          display: grid
          grid-template-columns: repeat(auto-fit, minmax(10rem, calc(25% - .5rem)))
          grid-row-gap: 2rem

          @media screen and (max-width: 600px)
            grid-template-columns: 1fr

          img
            width: 100%
            max-width: 100%

            & + div
              grid-column: 1 / span 2

              .blockinfo
                a
                  text-decoration: underline

        .section
          display: grid
          grid-template-columns: repeat(auto-fill, calc(50% - 1rem))
          grid-gap: 2rem
          align-items: start

          @media screen and (max-width: 600px)
            grid-template-columns: 1fr

          &.align-start
            align-items: start

          .status-box
            padding-top: 2rem

          .blockinfo
            grid-column: 1 / span 2

            @media screen and (max-width: 600px)
              grid-column: 1

          .input-group
            input, .button
              display: block
              width: 100%

              &:disabled
                background-color: $white

            .spinner
              // position: absolute
              padding-top: 2rem
              max-width: 3.2rem
              max-height: 3.2rem

              svg
                width: 3.2rem
                height: 3.2rem

          .iconwrap
            .country-flag
              height: 1rem
              max-width: 1rem
              margin: 0 .5rem 0 .1rem
              vertical-align: middle

          .dropdown
            display: block
            flex: 1 1 auto

            .button
              display: block
              width: 100%
              border-color: $gray-border
              border-radius: 0
              transition: background 0.3s ease, border 0.3s ease, box-shadow 0.3s ease

              &:hover
                background-color: transparent
                border-color: $gray-secondary

              &:focus
                border-color: $black

              .textandicon-wrap
                justify-content: flex-start

                span
                  margin-left: 0

              .caret
                margin-left: auto

            .inner-menu
              width: 100%

          .timezone-select
            width: 100%

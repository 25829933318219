@import 'styles/variables'

html[bkbn-style="old"]

  .order-detail
    position: relative
    width: 100%
    border: .1rem solid $gray-border
    box-sizing: border-box

    .content
      display: flex
      flex-direction: column
      box-sizing: border-box
      padding: 2rem

      .background
        background-color: $primary-orange

      .heading
        margin: 0

      .black
        color: $black

      .title
        font-size: 1.6rem

      .number
        display: inline-block
        color: $black
        padding: .5rem 1rem

      .margin-right
        margin-right: 1rem

      .name, .category
        @media screen and (max-width: 600px)
          margin-bottom: 1rem

      .line
        display: flex
        flex-wrap: wrap
        align-items: center
        box-sizing: border-box
        margin: .5rem 0 1rem

        @media screen and (max-width: 600px)
          flex-direction: column
          align-items: flex-start
          margin-bottom: 1rem

        .shortened-comment-field
          height: inherit

        &.vertical-align-start
          align-items: flex-start

      .group
        display: flex
        align-items: center
        box-sizing: border-box

        @media screen and (max-width: 600px)
          margin-bottom: 1rem

      span,
      strong,
      a
        display: inline-flex
        color: inherit
        text-decoration: none
        height: 1.15em
        transition: color .3s ease

        a:hover
          color: $secondary-orange-dark

      .pipedrive-link
        text-decoration: underline

      .separate-left
        display: inline-flex

        &:before
          display: inline-block
          margin: 0 .5rem
          margin-left: 1rem
          margin-top: -.12rem
          content: '•'
          color: $secondary-orange-dark

          @media screen and (max-width: 600px)
            display: none

      .icon
        &.small
          margin: 0 .5rem
          vertical-align: text-bottom
          height: 1.6rem

          &.mobileonly
            display: none

          @media screen and (max-width: 800px)
            display: inline-flex

      .main-wrapper
        display: grid
        grid-template-columns: repeat(3, minmax(0, 1fr))
        column-gap: 4rem

        .right
          justify-self: flex-end

          @media (max-width: 1000px)
            justify-self: flex-start

        .first-column
          grid-column: 1

        .first-column-span
          grid-column: 1 / span 2

        // .second-column
        //   grid-column: 2

        .third-column
          grid-column: 3

        .first-row
          grid-row: 1
          margin-top: 0

        .second-row
          grid-row: 2

        // .third-row
        //   grid-row: 3

        // .third-row-span
        //   grid-row: 3 / span 4
        //   align-self: flex-end

        // .fourth-row
        //   grid-row: 4

        @media (max-width: 1400px)
          grid-template-columns: repeat(2, minmax(0, 1fr))

        //   .third-column
        //     grid-column: 2

        @media (max-width: 1000px)
          grid-template-columns: repeat(1, minmax(0, 1fr))

          .first-column-span
            grid-column: 1

          .first-row, .second-row
            grid-row: auto

          // .first-column-span
          //   &.fourth-row
          //     grid-row: 6

          .first-column-span
            &.second-row
              grid-row: 3

          .third-column
            grid-column: 1

            &.first-row
              grid-row: 2

            &.second-row
              grid-row: 4

          // .third-column
          //   grid-column: 1

            // &.first-row
            //   grid-row: 2

            // &.third-row-span
            //   grid-row: 7

      .wrapper-three-columns
        display: grid
        grid-template-columns: repeat(3, minmax(0, 1fr))
        column-gap: 4rem

        .empty
          margin: 0

        .first-column
          grid-column: 1

        .second-column
          grid-column: 2

        .third-column
          grid-column: 3

        .first-row
          grid-row: 1
          margin-top: 1.5rem

        .second-row
          grid-row: 2

        // .third-row
        //   grid-row: 3

        // .fourth-row
        //   grid-row: 4

        // .fifth-row
        //   grid-row: 5

        // .sixth-row
        //   grid-row: 6

        // .seventh-row
        //   grid-row: 7

        @media (max-width: 1400px)
          grid-template-columns: repeat(2, minmax(0, 1fr))

          .third-column
            grid-column: 1 / span 2

            &.first-row, &.second-row
              grid-row: auto

        @media (max-width: 1000px)
          grid-template-columns: repeat(1, minmax(0, 1fr))

          .second-column
            grid-column: 1

          .third-column
            grid-column: 1

          .first-row, .second-row
            grid-row: auto

      .wrapper-two-columns
        display: grid
        grid-template-columns: repeat(2, minmax(0, 1fr))
        column-gap: 4rem

        .empty
          margin: 0

        .first-column
          grid-column: 1

        .second-column
          grid-column: 2

        .first-row
          grid-row: 1
          margin-top: 1.5rem

        .second-row
          grid-row: 2

        // .third-row
        //   grid-row: 3

        // .fourth-row
        //   grid-row: 4

        // .fifth-row
        //   grid-row: 5

        // .sixth-row
        //   grid-row: 6

        // .seventh-row
        //   grid-row: 7

        @media (max-width: 1000px)
          grid-template-columns: repeat(1, minmax(0, 1fr))

          .second-column
            grid-column: 1

          .first-row, .second-row
            grid-row: auto

@import 'styles/variables'

.expandableCard
  position: relative
  display: flex
  flex-direction: column
  box-sizing: border-box
  box-shadow: $secondary-card-shadow
  border: .1rem solid transparent

  &.disabled
    cursor: not-allowed
    color: $gray-text

    &:after
      content: ''
      position: absolute
      display: block
      top: 0
      right: 0
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      background-color: transparent

    .header
      background-color: $gray-background

  &.highlighted
    border: .1rem solid $secondary-orange-dark

  .header
    box-sizing: border-box
    background-color: $white
    padding: 2rem

    &.isClickable
      cursor: pointer

  .content
    position: relative
    background-color: $primary-orange
    padding: 2rem
    box-sizing: border-box

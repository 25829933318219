@import 'styles/variables'

.changeEmailPopup

  .content
    width: 60rem !important
    max-width: calc(100vw - 4rem)
    min-height: fit-content
    position: relative
    padding: 2.4rem 2.4rem 0rem 2.4rem
    box-sizing: border-box

  .successContent
    padding-bottom: 0.8rem

  .heading
    font-size: 2.2rem
    font-weight: normal
    margin: 0 0 1rem

  @media screen and (max-width: 600px)
    flex-direction: column
    align-items: flex-start

  .inputWrapper
    max-width: 50rem

@import 'styles/redesign-variables'

.signupPortal
  background-color: $beige-300
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  gap: 2.4rem

  &.mobile
    height: 100%
    padding: 4rem 0
    flex-direction: column
    justify-content: flex-start
    align-items: center

  .userLoggedIn
    max-width: 58rem
    box-sizing: border-box
    border-radius: .8rem
    background-color: $white
    padding: 4.8rem 2rem

    p
      text-align: justify

    h2
      margin-top: 0
@import 'styles/variables'

.linkify-wrapper
  transition: color .3s ease
  display: inline-block
  text-decoration: none
  color: $gray-text
  font-weight: bold

  &.orange:hover
    color: $secondary-orange-dark !important

  &.blue:hover
    color: $secondary-blue-dark !important

  &.green:hover
    color: $secondary-green-dark !important

  &.red:hover
    color: $secondary-red-dark !important

@import 'styles/variables'

.addWorkspaceMembersList

  h2, p
    margin: 0
    line-height: normal

  h2
    margin-bottom: 2rem

  p.info
    text-align: left
    font-size: 1.4rem

    a
      color: $secondary-orange-dark

  .addMembersSection
    margin-top: 2rem

  .maxMembersWarning
    margin-top: 1rem

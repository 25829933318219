.grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  column-gap: 6rem

  .row1
    grid-row: 1

  .row2
    grid-row: 2

  .row3
    grid-row: 3

  .row4
    grid-row: 4

  .row5
    grid-row: 5

  .row6
    grid-row: 6

  .row7
    grid-row: 7

  .row8
    grid-row: 8

  .row9
    grid-row: 9

  .row10
    grid-row: 10

  .column1
    grid-column: 1

  .column2
    grid-column: 2

  .column3
    grid-column: 3

  @media (max-width: 1400px)
    grid-template-columns: 1fr 1fr

    .column3
      grid-column: 1

      &.row1, &.row2, &.row3, &.row4, &.row5, &.row6, &.row7, &.row8, &.row9, &.row10
        grid-row: auto

      &.row1
        margin-top: 3rem

  @media (max-width: 1000px)
    grid-template-columns: 1fr

    .column2, .column3
      grid-column: 1

      &.row1, &.row2, &.row3, &.row4, &.row5, &.row6, &.row7, &.row8, &.row9, &.row10
        grid-row: auto

      &.row1
        margin-top: 3rem

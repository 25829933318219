@import 'styles/redesign-variables.sass'

.contentWrapper
  width: 100%
  gap: 2
  display: flex
  flex-flow: row
  justify-content: space-between

  font-weight: 500
  font-size: 1.6rem
  text-align: right
  color: $gray-900

  p
    margin: 0

  span
    text-align: flex-end

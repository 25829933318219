@import 'styles/redesign-variables'

.clientReference
  margin-top: 10.8rem
  text-align: center

  &.mobile
    margin-top: 5rem

  .title
    margin-bottom: 2.4rem
    font-size: 1.4rem
    font-weight: 500
    color: $gray-700

  .logos
    width: 100%

@import 'styles/variables'

html[bkbn-style="old"]

  .page.errorpage
    opacity: 1 !important

    .page-content
      .wrap
        .content
          padding: 5rem
          display: flex
          flex-direction: column
          align-items: flex-start
          background-color: $white
          box-shadow: $primary-card-shadow
          border-radius: $card-border-radius

          h1
            font-size: $h2-size
            margin: 0 0 2rem

          p
            font-size: 1.6rem
            margin: 0 0 1rem

          .button
            text-decoration: none

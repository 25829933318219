@import 'styles/redesign-variables'

.header
  gap: 1rem
  display: flex
  padding: 1rem 2rem
  align-items: center
  flex-direction: row
  user-select: none

  p
    color: $gray-900
    font-weight: 600
    font-size: 1.4rem

  .photo
    width: 4rem
    height: 4rem
    border-radius: 50%
    background-color: $gray-300

.footer
  display: flex
  color: $gray-900
  padding: 2rem
  flex-direction: column

  .rightIcon
    margin-left: auto

  textarea
    margin-top: 0.5rem
    border-radius: 0.8rem

    &:focus
      border-color: $blue-600 !important

  p
    font-size: 1.4rem
    line-height: 1.3

    span
      font-weight: 600

@import 'styles/redesign-variables'
@import 'styles/variables'

.tab
  display: inline-block
  border: none
  color: $gray-700
  font-size: 1.6rem
  padding: 0 .4rem .8rem .4rem
  background-color: unset
  cursor: pointer
  font-family: $font-family

  .totalCountText
    font-size: 1.2rem
    font-weight: 600

  &.mobile
    font-size: 1.8rem

  &.active
    color: $gray-900
    font-weight: bold
    border-bottom: solid .2rem $coral-600

  &.disabled
    cursor: not-allowed

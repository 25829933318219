@import 'styles/variables'
@import 'styles/theme-variables.sass'
@import 'styles/redesign-variables'

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 100
  background: adjust-color($DEEP_SPACE_BLACK, $alpha: -0.5)
  overflow: auto
  display: flex
  justify-content: center
  align-items: center

.modalContent
  position: relative
  width: 30rem
  max-width: calc(100% - 4rem)
  max-height: calc(100% - 4rem)
  background-color: $white
  overflow: hidden
  box-sizing: border-box
  box-shadow: $primary-card-shadow
  padding: 4rem
  border-radius: 1rem
  border-color: $CONTRAST_PAPER_WHITE
  box-shadow: 0 0 1rem .2rem adjust-color($DEEP_SPACE_BLACK, $alpha: -.7)

  display: flex
  flex-direction: column
  gap: 0
  align-items: flex-start
  justify-content: stretch

  .header
    width: 100%
    flex: 0 0 auto

    .title
      font-size: 2.5rem
      margin-bottom: 2rem
      color: $black
      font-weight: bold

      &.hasSubtitle
        margin-bottom: 1rem

    .subtitle
      font-size: 1.5rem
      color: $gray_700
      margin-bottom: 2rem

  .scrollableContent
    width: 100%
    flex: 1 1 100%
    overflow: auto

  .footer
    width: 100%
    flex: 0 0 auto

@import 'styles/variables.sass'
@import 'styles/redesign-variables.sass'

.errorText
  color: $coral-600 !important

.dayTitle
  margin: 0
  color: $gray-900
  font-weight: 500

.notFoundText
  font-weight: 500
  font-size: 1.4rem
  color: $gray-700

.timePickerWrapper
  gap: 2rem
  display: flex
  align-items: center
  flex-direction: row

  font-weight: 500
  font-size: 1.4rem
  color: $gray-900

  .picker.hasError
    border-color: $coral-600

.iconAdd
  color: $gray-800 !important

.iconDelete
  color: $secondary-red-dark !important

@import 'styles/variables'

html[bkbn-style="old"]

  .deal-assignment-price-breakdown
    padding: 2rem 0

    .section
      margin-bottom: 4rem

      &:last-child
        margin-bottom: 0

      h3
        margin: 0 auto 1rem

        .gray
          color: $gray-text
          display: inline-block
          margin-left: 1rem

      .line
        display: flex
        justify-content: space-between
        margin-bottom: .5rem

        &:last-child
          margin-bottom: 0

        strong
          display: inline-block
          margin-left: 4rem

          & + strong
            margin-left: 1rem

          &.nowrap
            white-space: nowrap

          span
            margin-right: 1rem

        &.total
          span
            margin-right: auto

          strong
            color: $black
            justify-self: flex-end

            &.total-before-discount
              display: block
              color: $gray-text
              text-decoration: line-through

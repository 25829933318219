@import 'styles/variables'

.changeMemberRolePopup

  .content
    width: 60rem !important
    max-width: calc(100vw - 4rem)
    min-height: fit-content
    font-size: 1.4rem

    @media screen and (max-width: 600px)
      width: 40rem

  .heading
    font-size: 2.2rem
    font-weight: normal
    margin: 0 0 1rem

  .text
    max-width: 100%

  .line
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    margin: .5rem 0 1rem
    opacity: 1

  .buttonWrapper
    justify-content: flex-end
    margin-top: 2rem

    @media screen and (max-width: 600px)
      justify-content: flex-start

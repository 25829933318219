@import 'styles/variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .topbar
    .profile-block
      display: flex

      a
        text-decoration: none

      .order-visuals-button
        box-shadow: 2px 2px 4px rgba(19, 24, 29, 0.1)
        height: 3.6rem
        background: $gray-900
        font-weight: 600

        @media screen and (max-width: 800px)
          display: none

  .topbar
    display: flex
    align-items: center
    padding: 1.4rem 2.4rem
    height: 6.4rem
    box-sizing: border-box
    animation: topbar-slide-in-from-top .3s ease-out forwards
    transition: padding .3s ease
    position: fixed
    width: 100%
    background-color: $white
    box-shadow: $box-shadow-bottom
    border-bottom: 1px solid $gray-200
    z-index: 100

    a
      text-decoration: none

    &.login-topbar
      background-color: transparent
      box-shadow: unset
      border-bottom: unset

    @keyframes topbar-slide-in-from-top
      from
        opacity: 0
        transform: translateY(-100%)

      to
        opacity: 1
        transform: translateY(0)

    @media screen and (max-width: 800px)
      height: 6.4rem
      padding: 1.4rem 2rem

    .logo-link
      display: flex
      align-content: center
      align-items: center
      opacity: 0
      transform: translateX(calc(-100% - 5rem))
      animation: topbar-slide-in-from-left .3s .3s ease-out forwards

      .logo img
        width: 11rem

      @keyframes topbar-slide-in-from-left
        from
          transform: translateX(calc(-100% - 5rem))
          opacity: 0

        to
          transform: translateX(0)
          opacity: 1

    .workspace
      display: flex
      align-items: center
      height: 2.4rem

      @media screen and (max-width: 800px)
        display: none

      .name
        font-size: 1.6rem
        font-weight: 600
        color: $gray-900
        padding-bottom: 0.3rem

    .push-right
      display: flex
      align-items: center
      gap: 2rem
      margin-left: auto
      margin-right: 0
      transform: translateX(calc(100% + 5rem))
      animation: topbar-slide-in-from-right .3s .3s ease-out forwards

      &.onlymobile
        display: none

        @media screen and (max-width: 800px)
          display: flex

      @keyframes topbar-slide-in-from-right
        from
          transform: translateX(calc(100% + 5rem))
          opacity: 0

        to
          transform: translateX(0)
          opacity: 1

    .burger-menu-button
      height: 3.6rem
      width: 6.2rem
      border-radius: 4rem
      border: 1px solid $gray-400
      padding: .2rem .8rem .2rem .1rem
      display: flex
      justify-content: space-between
      align-items: center
      gap: .4rem
      min-height: unset

      img
        width: 3rem
        height: 3rem
        border: 1px solid $white
        border-radius: 50%

    .profile-block
      justify-content: center

      @media screen and (max-width: 800px)
        display: none

    .login-buttons
      @media screen and (max-width: 800px)
        display: none

    .language-dropdown
      .language-trigger
        background-color: transparent
        padding: .4rem 0 0 0

      .inner-menu
        min-width: 0

        .language-button
          &.selected
            font-weight: bold

  .menu-wrap
    &.help-center-menu
      width: fit-content
      min-width: 20rem

      @media screen and (max-width: 800px)
        display: none

    .main-menu,
    .mobile-main-menu
      box-sizing: border-box
      flex-direction: column
      align-items: flex-start
      background: $white
      font-weight: 600
      line-height: 140%
      width: 100%
      margin: unset

      a
        text-decoration: none

      .section
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        border-bottom: unset
        width: 100%
        gap: .8rem
        margin: unset
        padding: unset
        font-size: 1.4rem
        position: relative

        &.light
          font-weight: 400

        & > *
          box-sizing: border-box
          padding: .4rem
          margin: unset
          width: 100%
          color: $gray-900
          display: flex
          align-items: center
          gap: .8rem
          cursor: pointer

          &:hover
            background: $beige-400
            border-radius: .2rem

        .button
          display: flex
          min-height: unset
          height: 2.8rem
          font-family: $font-family
          font-weight: inherit
          font-size: inherit

          svg
            margin-left: unset
            margin-right: .9rem
            width: 1.6rem
            height: 1.6rem
            font-size: 1.6rem
            padding-top: 0.4rem

        .user
          flex-direction: column
          align-items: flex-start
          gap: .2rem

          .name
            font-weight: 600

          .email
            color: $gray-700
            font-weight: 400

        .icon
          width: 1.8rem
          height: 1.8rem
          font-size: 1.8rem
          padding-top: 0.2rem

          &.external-link
            position: absolute
            right: .4rem
            font-size: 1.4rem

            path
              fill: $coral-600

          .footer
            flex-direction: row

    .main-menu
      padding: 1.4rem 1.6rem
      background: $white
      border: 1px solid $gray-200
      box-shadow: 0 .4rem 1.6rem rgba(19, 24, 29, 0.06)
      border-radius: .4rem
      font-size: 1.4rem

    .mobile-main-menu
      position: fixed
      top: 6.4rem
      right: 0
      bottom: 0
      left: 0
      height: calc( 100vh - 6.4rem )
      width: 100vw
      z-index: 50
      display: flex
      padding: 1.6rem 1.6rem 4.8rem 1.6rem

      .menu-list
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        height: 100%
        box-sizing: border-box

      .login-menu
        display: flex
        flex-direction: column
        gap: 2rem

      .section
        font-size: 1.6rem
        gap: 1.2rem

        & > *
          height: 4rem !important
          padding: .8rem .4rem

        .workspace-name
          font-size: 1.8rem
          color: $black

        .icon
          &.external-link
            font-size: 1.6rem

      .footer
        width: 100%
        margin-top: auto

        .user-block
          flex-direction: column
          align-items: center

        .menu-order-visuals-button
          height: 4.8rem
          font-size: 1.6rem
          font-weight: 600
          width: 100%

@import 'styles/redesign-variables'
@import 'styles/variables'

html[bkbn-style="old"]

  .helpCenterButton
    color: $gray-700 !important
    padding: unset !important

    .icon
      width: 1.6rem
      height: 1.6rem
      fill: $gray-600
      margin-right: .8rem

  .contactUsButton
    display: flex
    height: 2.75rem
    font-family: $font-family
    border-width: 0 !important
    white-space: nowrap

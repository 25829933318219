@import 'styles/theme-variables.sass'

.modalBody
  width: 90%
  max-width: 60rem
  display: flex
  flex-direction: column
  gap: 3rem
  justify-content: flex-start
  align-items: stretch
  color: $DEEP_SPACE_BLACK

  .title
    font-size: 3rem

  .content
    font-size: 1.6rem

  .actions
    display: flex
    justify-content: space-between
    align-items: center

  .button
    width: fit-content

  textarea:focus::placeholder
    color: $DEEP_SPACE_BLACK

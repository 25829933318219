@import 'styles/variables'

html[bkbn-style="old"]

  .client-onboarding

    h1
      font-size: 2em

    .group
      display: flex
      align-items: center

      .triangleicon
        margin-right: .5rem
        width: 5rem
        height: 5rem

        .triangle
          height: 5rem

        .inner-icon
          height: 3rem
          transform: translate(-50%, -40%)

    .blockinfo
      margin-bottom: 4rem

      .instruction-item
        padding-bottom: 0.4rem

        .bold
          font-weight: bold

    .inputs
      position: relative
      display: flex
      flex-direction: column
      margin-bottom: 2rem
      z-index: 1

      .input-row
        display: flex
        gap: 2rem
        margin-bottom: 2rem

        @media screen and (max-width: 800px)
          flex-direction: column

      .input-group
        display: flex
        flex-direction: column

        &:first-child
          margin-left: 0

        &.language-group
          min-width: 22rem

        &.vat-number-group, &.email-group
          flex-grow: 1

        &.button-group
          flex-direction: row
          gap: 2rem

        .email-input
          width: 100%

        .onboard-button
          margin-top: 2.3rem
          white-space: nowrap

        .resend-button
          margin-top: 2.3rem
          white-space: nowrap

        .language-dropdown
          display: block

          .button
            display: block
            width: 100%
            border-color: $gray-border
            border-radius: 0
            transition: background 0.3s ease, border 0.3s ease, box-shadow 0.3s ease

            &:hover
              background-color: transparent
              border-color: $gray-secondary

            &:focus
              border-color: $black

            .textandicon-wrap
              justify-content: flex-start

              span
                margin-left: 0

              .icon
                margin-left: auto

          .inner-menu
            width: 100%
            min-width: 0

    .onboarding-list
      display: flex
      flex-direction: column

      .item
        margin-bottom: 2rem

        .email
          color: $black
          margin-bottom: 1rem

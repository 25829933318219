.styleGrid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr))
  gap: 1rem
  justify-content: start

.imageWrap
  position: relative
  height: 20rem
  overflow: hidden

  .styleImage
    width: 100% !important

  .confirmButton
    position: absolute
    display: block
    width: calc(100% - 4rem)
    bottom: 2rem
    left: 50%
    transform: translate(-50%, -100%)
    text-align: center

.emptyState
  padding: 2rem
  width: 100%
  font-size: 1.6rem
  opacity: 0.5
  text-align: center

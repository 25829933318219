@import 'styles/variables'
@import 'styles/theme-variables.sass'

html[bkbn-style="old"]

  .rating-emojis

    &.blockinfo
      max-width: 100%
      display: block
      position: relative
      box-sizing: border-box
      padding: 1rem
      text-align: center

    .rating-emojis-wrapper
      margin-bottom: 0
      display: flex
      flex-direction: column
      text-align: center

      .rating-hint
        font-size: 1.4rem
        color: $MEDITERRANEAN_BLUE

      .rating-title
        font-size: 2rem
        font-weight: bold
        color: $heading-color
        margin: 1.5rem

      h3
        margin: 1.5rem

      .emojis-wrapper
        display: flex
        justify-content: center
        align-items: flex-start
        margin: 1rem 2rem

        .emoji-wrapper
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          height: auto
          margin: .3rem

          .emoji-button
            height: 100%
            width: 100%
            margin: 0
            padding: .5rem
            background-color: transparent
            border: 1px solid transparent
            cursor: pointer
            opacity: 0.5
            transition-property: scale, opacity
            transition-duration: .2s
            transition-timing-function: ease-in

            &:hover:enabled, &.active
              opacity: 1

              .emoji
                transform: scale(1.2)

              .emoji-description
                font-weight: 700
                transform: scale(1.1)

            &:disabled
              cursor: not-allowed
              opacity: 0.2

              &.active
                opacity: 1

            .emoji-description
              display: inline-block
              font-family: $font-family
              font-size: $font-size
              color: $text-color
              margin-top: 1rem

      .response
        margin: 0 2rem 1rem

        h3
          margin: 0

          &.error-heading
            margin-bottom: 1rem

@import 'styles/redesign-variables'
@import 'styles/variables'

.planCard
  box-sizing: border-box
  width: 36rem
  padding: 3.2rem
  box-shadow: 0px 1px 4px rgba(19, 24, 29, 0.06)
  border-radius: .8rem
  background: $white
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 5rem

  &.mobile
    max-width: 36rem

  .cardWrap
    .planLogo
      margin-bottom: 1.6rem
      background: linear-gradient(180deg, #FCF0ED 0%, rgba(252, 240, 237, 0) 100%)
      height: 5.6rem
      width: 5.6rem
      display: inline-block
      border-radius: 50%

      .innerCircle
        background: linear-gradient(180.88deg, #FAE4DE 0.76%, rgba(250, 228, 222, 0) 80.8%)
        height: 4rem
        width: 4rem
        margin: .8rem
        display: inline-block
        border-radius: 50%

      .img
        height: 2.2rem
        width: 2.2rem
        color: $coral-400
        font-size: 2.2rem
        margin: auto
        margin-top: .8rem

    .header
      margin: 1.6rem 0 1.5rem
      min-height: 9rem

      .title
        color: $gray-900
        font-size: 2.4rem
        font-weight: 400
        font-family: $ff-sangbleu
        margin-bottom: 1.2rem

      .description
        color: $gray-700
        font-size: 1.4rem
        font-weight: 400

    .planPrice
      height: 4rem
      margin: 0 0 1.5rem 0
      color: $gray-900

      .price
        font-size: 2.4rem
        font-weight: 600

      .teamPrice
        display: flex
        align-items: flex-end

        .priceWrap
          position: relative

          .originalPrice
            font-size: 1.2rem
            font-weight: 400
            color: $gray-800

          .strikeLine
            position: absolute
            width: 2rem
            height: .4rem
            box-sizing: border-box
            line-height: 4rem
            text-indent: 2rem

            &::before
              content: ""
              position: absolute
              left: 0
              top: .6rem
              width: 100%
              box-sizing: border-box
              border-bottom: 1px solid $gray-800
              transform-origin: bottom center
              transform: rotateZ(170deg)

          .discount
            font-size: 2.4rem
            font-weight: 600
            color: $gray-900
            padding-bottom: 0

        .userAmount
          text-align: end
          padding: 0 0 0.2rem 0.2rem

          .upToLabel
            font-size: 1.4rem
            font-weight: 400
            margin-right: .8rem
            color: $gray-700

    .info
      color: $gray-900
      font-size: 1.4rem

      .note
        font-weight: 600
        margin-bottom: 1.2rem

      .detail
        display: flex
        justify-content: flex-start
        align-items: flex-start
        font-weight: 400
        margin-bottom: 1.2rem

        .iconWrap
          height: 2rem
          width: 2rem
          border-radius: 50%
          display: flex
          justify-content: center
          align-items: center
          background-color: $coral-200
          flex-shrink: 0
          margin-right: 1.2rem

          .checkIcon
            color: $coral-600

  .buttonWrap
    .button
      width: 100%

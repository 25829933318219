@import 'styles/variables'

.subscriptionStep
  min-height: 100%
  display: flex
  flex-flow: column nowrap
  justify-content: flex-start
  align-items: stretch
  gap: 6rem

  p, h1
    text-align: center
    color: $black
    margin: 0

  header

    h1
      display: block
      padding-bottom: 2rem
      border-bottom: 1px solid $secondary-orange-dark
      font-weight: 400
      font-family: $ff-sangbleu
      font-size: 3.5rem

  .stepInfo
    p
      font-size: 1.6rem
      line-height: 2

      a
        color: $secondary-orange-dark
        font-weight: 500
        text-decoration: underline

  article
    border: 1px solid $gray-light-text
    padding: 4rem
    border-radius: 1.2rem

  footer
    display: flex

    &.center
      justify-content: center

    &.right
      justify-content: flex-end

    &.left
      justify-content: flex-start

    &.between
      justify-content: space-between

@import 'styles/redesign-variables'

.toggleFilter
  position: relative
  display: flex
  flex-flow: row wrap
  justify-content: flex-start
  gap: 1rem

  &::after
    position: relative
    content: ""
    flex: 10 0 auto

  .toggleButton
    flex: 1 0 auto

@import 'styles/redesign-variables.sass'

.modalContent
  width: 90%
  max-width: 80rem
  padding: 2rem
  box-sizing: border-box
  height: 90vh

  .searchInput
    box-sizing: border-box
    position: relative
    width: 100%
    max-width: 30rem

    .icon
      display: block
      position: absolute
      transform: translate(-50%, 50%)
      right: 0

    input
      display: block
      box-sizing: border-box
      border-radius: 4px
      width: 100%
      padding-right: 10rem

  .wrap
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2rem

  .ctCard
    grid-column: span 1
    display: block
    box-sizing: border-box
    border: 1px solid lightgray
    border-radius: 8px
    padding: 1rem

    .ctCardHeader
      margin-bottom: 2rem

      h3
        margin: 0
        margin-bottom: .25rem

      p
        margin: 0
        font-size: 1.4rem

    .section
      border-radius: 8px
      padding: 1rem
      background: $beige-400
      overflow: hidden

      h4
        margin: 0

      ul
        margin: 0
        padding: 0
        margin-top: .5rem
        list-style-type: none
        font-size: 1.4rem
        line-height: 1.5
        font-weight: 500

@import 'styles/variables'
@import 'styles/redesign-variables'

html[bkbn-style="old"]

  .pac-container
    border-radius: 8px
    margin-top: 0.5rem
    border: 1px solid $gray-400

    &.pac-logo:after
      display: none

    .pac-item
      border-top: 0
      cursor: pointer
      position: relative
      color: $gray-700 !important
      font-size: 1.4rem
      line-height: 100%
      box-sizing: border-box
      padding: 1.5rem 2.5rem 1.5rem 3.5rem

      .pac-item-query
        color: $gray-900

      &:before
        position: absolute
        top: 0
        left: 2.5rem
        content: ''
        height: .1rem
        width: calc(100% - 5rem)
        background-color: $gray-border

      &:first-child
        &:before
          display: none

      .pac-icon.pac-icon-marker
        display: none

  .auto-complete
    display: flex
    flex-direction: column

    input
      width: 100%
      height: 4rem
      box-sizing: border-box
      padding: .8rem 1.2rem .8rem 3.5rem
      background-image: url('../../../../assets/img/icons/search.svg')
      background-repeat: no-repeat
      background-size: auto 1.5rem
      background-position: 1rem center

      border-radius: 8px
      border: 1px solid $gray-400
      color: $gray-900
      font-size: 1.6rem
      box-shadow: $box-shadow-xs

      @media screen and (max-width: 600px)
        width: 100%

        &.above
          margin-bottom: 2rem

        &.below
          margin-top: 2rem

        &:placeholder
          color: $gray-600

        &:focus
          outline: none

        &.error-input
          border-color: $coral-600

          &:hover,
          &:focus
            border-color: $coral-600

      span.error-message
        display: inline-block
        color: $coral-600
        font-size: 1.2rem
        font-weight: bold
        margin: 0.5rem 0

    &.right
      .auto-complete
        input
          padding-left: 1.5rem
          padding-right: 3rem
          background-position: right 1rem center

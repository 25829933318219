@import '../../node_modules/react-datepicker/dist/react-datepicker.css'

html[bkbn-style="old"]

  .react-datepicker-wrapper
    .react-datepicker__input-container
      input
        width: 100%

  .react-datepicker
    font-family: $font-family
    border-radius: 0
    border-color: $gray-border
    font-size: 1.2rem

    .react-datepicker__header
      background-color: $primary-orange
      border-color: $gray-border

  .react-datepicker-popper
    z-index: 2

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle
      border-bottom-color: $primary-orange

      &::before
        border-bottom-color: $gray-border

  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle
      &::before
        border-top-color: $gray-border

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected
    background-color: $secondary-orange-light
    color: $black
    transition: background .15s ease

    &:hover
      background-color: $secondary-orange-dark

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text
    border-radius: 0
    transition: background .15s ease

    &:hover
      border-radius: 0
      background-color: $gray-light-text

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range
    border-radius: 0
    color: $black
    background-color: $secondary-orange-light

    &:hover
      background-color: $secondary-orange-dark

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today
    background-color: $primary-orange

  .react-datepicker__navigation--next
    border-left-color: $black

  .react-datepicker__navigation--previous
    border-right-color: $black

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list
    li.react-datepicker__time-list-item
      height: auto
      padding: 1rem .5rem
      transition: background .15s ease

    li.react-datepicker__time-list-item--selected
      color: $black
      background-color: $secondary-orange-light

      &:hover
        background-color: $secondary-orange-dark

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name
    width: 2.6rem
    line-height: 2.6rem
    margin: 0.25rem

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header
    font-size: 1.4rem

  .react-datepicker__navigation
    top: 1.2rem

.galleryNotFound
  gap: 2rem
  padding: 4rem
  text-align: center

  h3
    margin-top: 0

.marginTop
  margin-top: 2rem

@import 'styles/variables'

.checkcard

  .header
    display: flex
    align-items: center
    gap: 2rem

    &.disabled
      background-color: $gray-background

      \:global(.checkbox input:not(:checked) ~ .checkmark)
        background-color: $gray-background

    .content
      flex: 1 1 auto

  .expandableContent

    &.hasPaddingLeft
      padding-left: 4rem

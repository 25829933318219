@import 'styles/redesign-variables'
@import 'styles/variables'

.upcomingFeature
  margin: 10rem 0

  .header
    color: $gray-900
    font-size: 3rem
    font-weight: 400
    font-family: $ff-sangbleu
    margin-bottom: 3.8rem
    text-align: center

  .featureWrap
    max-width: 88rem

    &.wide
      margin: auto

    .feature
      position: relative
      display: flex
      flex-direction: column
      align-items: center

      .featureImg
        width: 25.6rem
        height: 25.6rem
        border-radius: .8rem
        background-color: $gray-700
        margin-bottom: 1.6rem

      .title
        font-size: 2rem
        font-weight: 400
        line-height: 3rem
        color: $gray-900
        text-align: center

      .tag
        position: absolute
        top: 1rem
        left: 0.5rem

      &.mobile
        .featureImg
          width: unset
          height: unset
          max-width: 100%
          max-height: 100%
          object-fit: contain

        .title
          font-size: 1.4rem
          line-height: 2rem

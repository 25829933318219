@import 'styles/redesign-variables'

.iconWrapper
  position: absolute

  .icon
    color: $coral-400

.mainCircle
  background: $coral-200
  animation: scaleup 1.5s ease-in-out infinite

.circle
  width: 4rem
  height: 4rem
  position: absolute
  border-radius: 50%
  background: $coral-200
  animation: pulse 1.5s ease-in-out infinite

.circle:nth-child(2)
  animation-delay: 0.4s

@keyframes scaleup
  0%
    transform: scale(1)

  50%
    transform: scale(1.1)

@keyframes pulse
  0%
    transform: scale(1)

  100%
    transform: scale(2)
    background-color: rgba(255, 255, 255, 0)

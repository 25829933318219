@import 'styles/variables'
@import 'styles/redesign-variables'

.wrapper
  gap: 1rem
  display: flex
  flex-flow: column wrap

  padding: 2rem
  border: .1rem solid $gray-border

  & label
    color: $gray-900
    font-size: 1.4rem
    margin: 0 !important
    font-family: $font-family

  .header

    h3
      margin: 0
      color: $gray-900

    p
      margin: 0.5rem 0 0
      font-size: 1.4rem
      color: $gray-700

  .instructions
    width: 100%

    .checkboxes
      margin-left: -9px

    .linksWrapper

      & button
        margin-top: 1rem

      & input
        width: 100%
        max-width: 40rem
        margin-top: 1rem

      & a
        font-size: 1.4rem

  .comments
    & textarea
      width: 100%
      max-width: 40rem
      min-width: 40rem
      font-size: 1.4rem
      padding: 0.8rem 1.5rem

      &:disabled
        background-color: $gray-400

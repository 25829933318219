@import 'styles/variables'
@import 'styles/redesign-variables'

.modalContent
  position: relative
  height: 88vh
  width: 95%
  max-width: 1600px
  padding: 0
  background: $beige-600

  &.isGreen
    background: $green-stage-light

  &.isRed
    background: $red-stage-light

  &.isSmall

    .scrollableContent
      flex-flow: column-reverse nowrap

      .left
        flex: 1 1 10rem

        &.isFullscreen
          flex: 0 0 100%

          .leftStep
            padding-left: 4rem

      .right
        flex: 1 1 100%

  .scrollableContent
    display: flex
    flex-flow: row wrap
    align-items: stretch
    overflow: hidden
    will-change: background
    transition: background 300ms ease

    .left, .right
      position: relative

    .left
      position: relative
      box-sizing: border-box
      height: 100%
      flex: 0 0 40rem

      &.isFullscreen
        flex: 0 0 40%
        min-width: 40rem

        .leftStep
          padding-left: 10rem

      .leftStep
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        bottom: 0
        right: 0

    .right
      box-sizing: border-box
      height: 100%
      position: relative
      flex: 1 0 300px
      background: white
      border-radius: 1rem
      box-shadow: 0 0 2rem -1rem rgba(0, 0, 0, 0.15)
      overflow: hidden
      display: flex
      flex-flow: column nowrap
      justify-content: stretch
      align-items: flex-start

      &.isFullscreen
        background: transparent
        box-shadow: none
        flex: 1 1 300px

      .scrollable
        box-sizing: border-box
        flex: 1 1 auto
        width: 100%
        overflow: auto
        padding: 4rem

        &.noscroll
          overflow: hidden

        h1, h3
          margin: 0
          color: $gray-text

        .csat
          margin: 0 auto
          width: 100%
          max-width: 600px
          display: flex
          flex-flow: column
          gap: 4rem
          text-align: center

        .stagingList
          .titleBar
            margin-bottom: 8rem

      .actionBar
        box-sizing: border-box
        width: 100%
        padding-top: 1rem
        padding-right: 4rem
        padding-left: 4rem
        flex: 0 0 8rem
        display: flex
        justify-content: flex-end
        align-items: flex-start

@import 'styles/variables'

$dark-colors: ('gray': $gray-text, 'blue': $secondary-blue-dark, 'orange': $secondary-orange-dark, 'red': $secondary-red-dark, 'green': $secondary-green-dark)
$light-colors: ('gray': $gray-background, 'blue': $primary-blue, 'orange': $primary-orange, 'red': $secondary-red-light, 'green': $primary-green)
$colors: 'gray', 'blue', 'orange', 'red', 'green'

.badge
  position: relative
  display: inline-block
  box-sizing: border-box
  font-size: 1.2rem
  height: 2.2rem
  padding: .3rem 1rem
  overflow: hidden
  line-height: 1
  font-weight: 800
  white-space: nowrap

  &.fill
    color: $white

    @each $color in $colors
      &.#{$color}
        background-color: map-get($dark-colors, #{$color})
        border: .1rem solid map-get($dark-colors, #{$color})

    svg
      fill: $white !important

  &.fillAlt
    color: $white

    @each $color in $colors
      &.#{$color}
        background-color: map-get($light-colors, #{$color})
        border: .1rem solid map-get($light-colors, #{$color})
        color: map-get($dark-colors, #{$color})

  &.outline
    @each $color in $colors
      &.#{$color}
        color: map-get($dark-colors, #{$color})
        border: .1rem solid map-get($dark-colors, #{$color})

.badgeWithIcon
  position: relative
  height: 2.8rem
  font-size: 1.4rem

  @each $color in $colors
    &.#{$color}
      .outline
        border: .1rem solid map-get($dark-colors, #{$color})

.badgeWithBorder
  border-radius: 4px

.textAndIconWrap
  display: flex
  align-items: center
  overflow: hidden
  position: relative
  height: 100%

  .dot
    display: flex
    align-items: center
    justify-content: center
    margin-right: 1rem
    height: 1.8rem
    width: 1.8rem
    background-color: $white
    border-radius: 50%

    & > svg
      height: 70%
      fill: $black !important

  .fullSize
    display: flex
    width: 1.8rem
    height: 1.8rem
    margin-right: 1rem
    align-items: center
    justify-content: center

    & > svg
      height: 100%

      @each $color in $colors
        &.#{$color}
          fill: map-get($dark-colors, #{$color})

.badgeWithIconAndArrow
  overflow: inherit
  margin-right: 1.5rem

  &:after
    content: ''
    position: absolute
    right: -1.5rem
    top: -0.1rem
    width: 0
    height: 0
    border-bottom: 1.4rem solid transparent
    border-top: 1.4rem solid transparent

  &.fill
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.4rem solid map-get($dark-colors, #{$color})

  &.fillAlt
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.4rem solid map-get($light-colors, #{$color})

  &.outline
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.4rem solid map-get($dark-colors, #{$color})

.badgeWithArrow
  overflow: inherit
  margin-right: 2.5rem

  &:after
    content: ''
    position: absolute
    right: -1.2rem
    top: -0.1rem
    width: 0
    height: 0
    border-bottom: 1.1rem solid transparent
    border-top: 1.1rem solid transparent

  &.fill
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.1rem solid map-get($dark-colors, #{$color})

  &.fillAlt
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.1rem solid map-get($light-colors, #{$color})

  &.outline
    @each $color in $colors
      &.#{$color}:after
        border-left: 1.1rem solid map-get($dark-colors, #{$color})

@import 'styles/variables'
@import 'styles/theme-variables.sass'

.borderWrapper
  display: flex
  flex-wrap: wrap
  margin-top: 4rem
  padding: 1rem 2rem
  box-sizing: border-box
  border: .1rem solid $gray-border

  .header
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    @media screen and (max-width: 800px)
      flex-direction: column-reverse

  .premiumBadge
    height: 2.2rem
    border-radius: 1.6rem
    background: $MEDITERRANEAN_BLUE
    padding: .2rem 1rem .2rem 1.5rem

    @media screen and (max-width: 800px)
      margin-left: auto
      margin-bottom: 0.5rem

    .badgeText
      color: #FFFFFF
      line-height: 1.4
      font-size: 1.6rem
      letter-spacing: 0.5rem
      text-transform: uppercase
  p
    margin: 0
    text-align: justify

  button
    margin-top: 2rem
    margin-left: auto

@font-face
  font-family: 'Sailec'
  src: url('../../assets/fonts/sailec/Sailec-Light.woff2') format('woff2'), url('../../assets/fonts/sailec/Sailec-Light.woff') format('woff'), url('../../assets/fonts/sailec/Sailec-Light.ttf') format('truetype'), url('../../assets/fonts/sailec/Sailec-Light.svg#Sailec-Light') format('svg')
  font-weight: 300
  font-style: normal
  font-display: swap
  descent-override: normal
  ascent-override: 105%

@font-face
  font-family: 'Sailec'
  src: url('../../assets/fonts/sailec/Sailec-Regular.woff2') format('woff2'), url('../../assets/fonts/sailec/Sailec-Regular.woff') format('woff'), url('../../assets/fonts/sailec/Sailec-Regular.ttf') format('truetype'), url('../../assets/fonts/sailec/Sailec-Regular.svg#Sailec-Regular') format('svg')
  font-weight: normal
  font-style: normal
  font-display: swap
  descent-override: normal
  ascent-override: 105%
